/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Otis Admin PRO React example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Otis Admin PRO React page layout routes
import pageRoutes from "page.routes";

// Images
import bgPrice from "assets/images/illustrations/background-sign-in.jpg";
import BrandingLogo from "assets/images/small-logos/branding-logo.svg";
// eslint-disable-next-line import/no-unresolved
import FooterImage from "assets/images/icons/pay/footer-img.svg";
import { useNavigate } from "react-router-dom";
import { useFlatInject } from "store";

function Header({ tabValue, tabHandler, children }) {
  const navigate = useNavigate();
  const [user] = useFlatInject("user");

  const handleClickLogin = async () => {
    await user.logout();
    navigate("/sign-in");
  };
  return (
    <MDBox>
      <MDBox
        position="relative"
        minHeight="100vh"
        height="100%"
        borderRadius="xl"
        m={2}
        pt={2}
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { black } }) =>
            `${linearGradient(rgba(black.main, 0.25), rgba(black.main, 0.25))}, url(${bgPrice})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <MDBox
          container
          width={{ xs: "86%", lg: "70%" }}
          m="auto"
          sx={{
            position: "relative",
            textAlign: "center",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <MDBox display="flex" justifyContent="space-between" py="24px">
            <MDBox component="img" src={BrandingLogo} />

            <MDBox display="flex" gap="6px">
              <MDTypography
                sx={{
                  fontWeight: "regular",
                  fontSize: "14px",
                  margin: "auto 0",
                  color: "#F5F7FA",
                }}
              >
                Already have an account?
              </MDTypography>

              <MDBox sx={{ cursor: "pointer" }} onClick={handleClickLogin}>
                <MDTypography
                  component="span"
                  fontWeight="medium"
                  sx={{
                    textDecoration: "underline !important",
                    fontSize: "14px",
                    color: "#FFF",
                  }}
                >
                  Login
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
          <MDTypography color="white" variant="h1" fontWeight="regular">
            Pricing
          </MDTypography>
          <MDBox>{children}</MDBox>
          <MDTypography
            color="white"
            fontWeight="regular"
            fontSize="18px"
            spacing="0.09px"
            marginTop="66px"
          >
            Brought to you by
          </MDTypography>
          <MDBox width="100%" display="flex" justifyContent="center" my="24px">
            <MDBox
              component="img"
              src={FooterImage}
              width="390px"
              height="80px"
              sx={({ breakpoints }) => ({
                [breakpoints.up("xs")]: {
                  width: "80%",
                },
              })}
            />
          </MDBox>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

// Typechecking props for the Header
Header.propTypes = {
  tabValue: PropTypes.number.isRequired,
  tabHandler: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default Header;

/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import { RadioGroup, Radio, FormControlLabel } from "@mui/material";
import React, { forwardRef, useCallback, useMemo, useRef } from "react";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

// Data
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDRadio from "components/MDRadio";
import Table from "components/Table";
import { useFlatInject } from "store";
import { omit } from "lodash";
import { Message } from "utils/message";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm, Form, FormItem } from "../../../../../components/Form";

const radioUI = <MDRadio />;
const fieldStyle = { flex: 1 };
const labelSx = { display: "none" };

const QuestionForm = React.forwardRef((props, ref) => {
  const [{ dealDue }] = useFlatInject("dealPlanOperation");
  const form = useForm({
    initialValues: {
      questions: dealDue.questions,
    },
  });

  React.useImperativeHandle(ref, () => ({
    getFormData: () => form.values,
    validate: () => form.validateForm(),
  }));
  const columns = useMemo(
    () =>
      dealDue.questions.map((q, qindex) => ({
        title: q.title,
        children: [
          {
            key: "item",
            title: "Item",
            width: 50,
            colSpan: (data) => (data.tips ? "0" : "1"),
            render: (data, index) => <span>{index + 1}</span>,
          },
          {
            key: "name",
            title: "Name",
            colSpan: (data) => (data.name ? "1" : "3"),
            render: (data, index) => (
              <MDBox maxWidth={data.name ? 500 : 1000}>{data.name || data.tips}</MDBox>
            ),
          },
          {
            key: "value",
            title: "Yes/No",
            width: 200,
            colSpan: (data) => (data.name ? "1" : "0"),
            render: (rowData, index) => (
              <FormItem as={RadioGroup} name={`questions[${qindex}].children[${index}].value`}>
                <MDBox display="flex" flexDirection="row">
                  <FormControlLabel
                    value="Y"
                    control={radioUI}
                    label={<MDTypography variant="subtitle2">Yes</MDTypography>}
                  />
                  <FormControlLabel
                    value="N"
                    control={radioUI}
                    label={<MDTypography variant="subtitle2">No</MDTypography>}
                  />
                </MDBox>
              </FormItem>
            ),
          },
        ],
      })),
    []
  );
  const uiMemo = useMemo(
    () =>
      columns.map((i, idx) => (
        <MDBox key={i.title}>
          <MDBox pt={3}>
            <MDTypography variant="h6">{i.title}:</MDTypography>
          </MDBox>
          <Table columns={i.children} data={dealDue.questions[idx].children} />
        </MDBox>
      )),
    []
  );
  return (
    <Form form={form} fieldStyle={fieldStyle} labelSx={labelSx}>
      {uiMemo}
    </Form>
  );
});

const DealNotesForm = forwardRef((props, ref) => {
  const [{ dealDue, dealReview }] = useFlatInject("dealPlanOperation");
  const form = useForm({
    initialValues: {
      dealNotes: dealDue.notes,
    },
    onSubmit: props.submit,
  });
  React.useImperativeHandle(ref, () => ({
    getFormData: () => form.values,
    validate: () => form.validateForm(),
  }));
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Form form={form} fieldStyle={fieldStyle} labelSx={labelSx}>
      <MDBox pt={3}>
        <MDTypography variant="h6">Deal Notes:</MDTypography>
      </MDBox>
      <MDBox xs={12} mt={2}>
        <FormItem
          as={MDInput}
          multiline
          type="text"
          name="dealNotes"
          variant="standard"
          placeholder="enter here"
          fullWidth
        />
      </MDBox>
      <Grid mt={4} item xs={12} textAlign="right">
        <MDButton
          color="green"
          variant="outlined"
          onClick={() => navigate("/deal-planner", { replace: true })}
          sx={{ mr: 1 }}
        >
          Cancel
        </MDButton>
        <MDButton
          loading={form.isSubmitting}
          disabled={form.isSubmitting || !dealReview.businessName}
          onClick={form.submitForm}
          color="green"
        >
          Save and next
        </MDButton>
      </Grid>
    </Form>
  );
});

function DealDue() {
  const [{ saveDealDue }] = useFlatInject("dealPlanOperation");

  const questionFormRef = useRef();
  const dealNotesRef = useRef();
  const location = useLocation();
  const submit = useCallback(async () => {
    const questionsFormData = questionFormRef.current?.getFormData?.();
    const dealNotesRefFormData = dealNotesRef.current?.getFormData?.();
    try {
      await saveDealDue({
        questions: questionsFormData.questions,
        notes: dealNotesRefFormData.dealNotes,
      });
      window.location.href = `${location.pathname + location.search}#final-term-sheet`;
      Message.success("save success!");
    } catch (error) {
      Message.error(error.message);
    }
  }, [saveDealDue]);

  return (
    <Card id="deal-due" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h4">Deal Discovery</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <QuestionForm ref={questionFormRef} />
        <DealNotesForm submit={submit} ref={dealNotesRef} />
      </MDBox>
    </Card>
  );
}

export default DealDue;

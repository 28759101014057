/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Otis Admin PRO React components
import MDBox from "components/MDBox";

// Otis Admin PRO React example components
import DefaultPricingCard from "examples/Cards/PricingCards/DefaultPricingCard/newPricingCard";

// Otis Admin PRO React context
import { useMaterialUIController } from "utils/hooks";
import { useFlatInject } from "store";

function PricingCards() {
  const [controller] = useMaterialUIController();
  const [{ user, openPayment }] = useFlatInject("user");
  const { darkMode } = controller;
  const payment = async (type) => {
    const state = await openPayment("monthly", type);
    if (state.openPaymentRes.checkoutUrl) {
      window.location.href = state.openPaymentRes.checkoutUrl;
    }
  };
  return (
    <MDBox zIndex={10} mt={8} px={{ xs: 1, sm: 0 }} p="0px">
      <Grid container rowGap={3} justifyContent="center" mx="auto" maxWidth="1400px">
        <Grid item xs={12} lg={6} pr={{ xs: 0, lg: 12 / 8 }}>
          <DefaultPricingCard
            color="white"
            badge={{ color: darkMode ? "warning" : "light", label: "Basic" }}
            price={{ currency: "$", value: 39, type: "mo" }}
            specifications={[
              { label: "Receive full upgrades along the progress", includes: true },
              { label: "Full Functional Online Deal Project management platform", includes: true },
              {
                label: "A tool helps you to find out the opportunity and potential problem",
                includes: true,
              },
              {
                label:
                  "Carefully designed parameters to lay out your condition, and make it clear to make important decisions",
                includes: true,
              },
              {
                label: "Utilize the pre-generated Term Sheet to built your Contract easily",
                includes: true,
              },
            ]}
            onClick={() => payment(0)}
            action={{
              type: "internal",
              route: "/",
              color: "green",
              label: "Join now",
            }}
            shadow={darkMode}
          />
        </Grid>

        <Grid item xs={12} lg={6} pl={{ xs: 0, lg: 12 / 8 }}>
          <DefaultPricingCard
            color="white"
            badge={{ color: darkMode ? "dark" : "light", label: "Deal Maker" }}
            price={{ currency: "$", value: 199, type: "mo" }}
            specifications={[
              { label: "Receive full upgrades along the progress", includes: true },
              { label: "Fully designed specified forms to generate better deals", includes: true },
              { label: "Save more than pay in Monthly subscription", includes: true },
              {
                label: "A tool helps you to find out the opportunity and potential problem",
                includes: true,
              },
              {
                label:
                  "Carefully designed parameters to lay out your condition, and make it clear to make important decisions",
                includes: true,
              },
            ]}
            action={{
              type: "internal",
              route: "",
              color: "green",
              label: "Join now",
            }}
            onClick={() => payment(1)}
            shadow={darkMode}
          />
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default PricingCards;

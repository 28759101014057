import MDInput from "components/MDInput";
import { useState } from "react";
import numeral from "numeral";
import { InputAdornment } from "@mui/material";
import MDTypography from "components/MDTypography";

export function MoneyInput({ value, ...props }) {
  const [focus, setFocus] = useState(false);
  return (
    <MDInput
      {...props}
      type={focus ? "number" : "text"}
      value={focus ? value : numeral(value).format("0,0.00")}
      onFocus={(e) => {
        setFocus(true);
        props.onFocus?.(e);
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <MDTypography variant="button" color="black">
              $
            </MDTypography>
          </InputAdornment>
        ),
      }}
      placeholder="0.00"
      onBlur={(e) => {
        setFocus(false);
        props.onBlur?.(e);
      }}
    />
  );
}

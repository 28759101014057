/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

// Data
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useFlatInject } from "store";
import { styled } from "@mui/material";
import { useForm, Form, FormItem } from "../../../../../components/Form";

const ZebraPrintPWarp = styled(MDBox)`
  width: 100%;
  .MuiGrid-root {
    padding: 0;
    margin: 20px;
  }
  p {
    border-bottom: 1px solid #ccc;
    padding: 20px 0px;
  }
`;

export const ZebraPrintBoxWarp = styled(MDBox)`
  width: 100%;
  border: 1px solid #ccc;
  padding: 0 !important;
  margin: 20px;
  & > *:nth-child(odd) {
    background-color: rgb(242, 242, 242);
  }
  & > * {
    padding: 20px 20px;
  }
  &,
  .MuiGrid-item {
  }
  /* .MuiGrid-root {
    border: 1px solid #ccc;
    padding: 0;
    margin: 20px;
    .MuiBox-root:nth-child(odd) {
      background-color: rgb(242, 242, 242);
    }
    .MuiBox-root {
      padding: 20px 20px;
    }
    p:nth-child(odd) {
      background-color: rgb(242, 242, 242);
    }
    p {
      padding: 20px 20px;
    }
  } */
`;

function Item({ name, value }) {
  return (
    <MDTypography color="#757575" fontSize={14}>
      {name}: <span style={{ color: "#000000", fontSize: 16 }}>{value}</span>
    </MDTypography>
  );
}

function TermSheet() {
  const [
    {
      dealReview,
      dealAnalysis,
      dealBundleWorkSheet,
      preClosingStrategyTable,
      gapFundingStrategyTable,
      postClosingStrategyTable,
      calculateTotal,
      nextStep,
    },
  ] = useFlatInject("dealPlanOperation");
  return (
    <Card id="deal-pitch" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h4">Deal Summary</MDTypography>
      </MDBox>
      <MDBox pb={3} px={3}>
        <Grid container spacing={3}>
          <ZebraPrintPWarp>
            <MDBox px={3} pt={3} width="100%">
              <MDTypography variant="h6">Deal Overview</MDTypography>
            </MDBox>
            <MDBox px={3}>
              <MDTypography variant="h6" color="green">
                Pre Meet
              </MDTypography>
            </MDBox>
            <Grid item xs={12}>
              <Item
                name="Product / Service Overview"
                value={dealReview.productAndServiceOverview}
              />
              <Item name="Industry Info" value={dealReview.industryInfo} />
              <Item name="Timing" value={dealReview.timing} />
              <Item name="Industry Multiple" value={dealReview.industryMultiplie} />
              <Item name="Connection In Industry" value={dealReview.connectionInIndustry} />
              <Item name="Impact to Empire" value={dealReview.impactToEmpire} />
            </Grid>
            <MDBox px={3} pt={3}>
              <MDTypography variant="h6" color="green">
                First Meet Criticals
              </MDTypography>
            </MDBox>
            <Grid item xs={12}>
              <Item name="Motivation to Sell" value={dealReview.impactToEmpire} />
              <Item name="Their Personal Story" value={dealReview.theirPersonalStory} />
              <Item name="The Business Story" value={dealReview.theBusinessStory} />
              <Item name="Owner Is A" value={dealReview.ownerIsA} />
              <Item name="Number Of Team" value={dealReview.numberOfTeam} />
              <Item name="Going Concern" value={dealReview.goingConcern} />
              <Item
                name="What's Challenging About The Business"
                value={dealReview.businessChallenge}
              />
              <Item name="AVG Monthly Turn Over" value={dealReview.avgMonthlyTurnOver} />
              <Item name="AVG Monthly Expenses" value={dealReview.avgMonthlyExpenses} />
              <Item name="AVG Monthly Profit" value={dealReview.avgMonthlyProfit} />
              <Item name="Time Frames Sell" value={dealReview.timeFramesSell} />
              <Item name="Any Other Leverage Points" value={dealReview.anyOtherLeveragePoints} />
              <Item name="DEAL NOTES" value={dealReview.dealReviewNotes} />
            </Grid>

            <MDBox px={3} pt={3} width="100%">
              <MDTypography variant="h6">Deal Analysis</MDTypography>
            </MDBox>
            <MDBox px={3} pt={3}>
              <MDTypography variant="h6" color="green">
                Business Overview
              </MDTypography>
            </MDBox>
            <Grid item xs={12}>
              {dealAnalysis.businessOverviewForm.map((i) => (
                <Item name={i.label} key={i.value} value={`$ ${i.value}, ${i.notes}`} />
              ))}
            </Grid>
            <MDBox px={3} pt={3}>
              <MDTypography variant="h6">Deal Overview</MDTypography>
            </MDBox>
            <Grid item xs={12}>
              {dealAnalysis.dealOverviewForm.map((i) => (
                <Item name={i.label} key={i.value} value={`$ ${i.value}, ${i.notes}`} />
              ))}
              <Item name="DEAL NOTES" value={dealAnalysis.dealNotes} />
            </Grid>
          </ZebraPrintPWarp>

          <MDBox px={3} pt={3} width="100%">
            <MDTypography variant="h6">Deal Bundle WorkSheet</MDTypography>
          </MDBox>
          <ZebraPrintBoxWarp component={Grid} item xs={12}>
            <MDBox display="flex">
              <MDTypography variant="body2" flex={1}>
                Ask Price: ${dealBundleWorkSheet.askPrice}
              </MDTypography>
              <MDTypography flex={1} variant="body2">
                Ebitda: {dealBundleWorkSheet.ebitda}
              </MDTypography>
              <MDTypography flex={1} variant="body2">
                Ask Multiple: {dealBundleWorkSheet.askMultiple}
              </MDTypography>
            </MDBox>
            <MDBox display="flex">
              <MDTypography flex={2} variant="body2">
                Goal Purchase Price: {dealBundleWorkSheet.preClosingStrategy.goalPurchasePrice}
              </MDTypography>
              <MDTypography flex={1} variant="body2">
                Goal Multiple: {dealBundleWorkSheet.preClosingStrategy.goalMultiple}
              </MDTypography>
            </MDBox>
          </ZebraPrintBoxWarp>

          <MDBox px={3} pt={3}>
            <MDTypography variant="h6">Pre-Closing Strategy</MDTypography>
          </MDBox>
          <ZebraPrintBoxWarp component={Grid} item xs={12}>
            {preClosingStrategyTable.map((i) => (
              <MDTypography variant="body2" key={i.label}>
                {i.label}: $ {i.value}, {i.notes}
              </MDTypography>
            ))}
            <MDTypography variant="body2">
              Total Cash Impact : $ {calculateTotal(preClosingStrategyTable, ["Y", "N"])}
            </MDTypography>
          </ZebraPrintBoxWarp>

          <MDBox px={3} pt={3}>
            <MDTypography variant="h6">Gap Funding Strategy</MDTypography>
          </MDBox>
          <ZebraPrintBoxWarp component={Grid} item xs={12}>
            <MDBox display="flex">
              <MDTypography flex={1} variant="body2">
                Goal Purchase Price: ${dealBundleWorkSheet.gapFundingStrategy.goalPurchasePrice}
              </MDTypography>
              <MDTypography flex={1} variant="body2">
                Deal Bundle Value: ${dealBundleWorkSheet.gapFundingStrategy.dealBundleValue}
              </MDTypography>
              <MDTypography flex={1} variant="body2">
                Gap Left: ${dealBundleWorkSheet.gapFundingStrategy.gapLeft}
              </MDTypography>
            </MDBox>
          </ZebraPrintBoxWarp>
          <ZebraPrintBoxWarp component={Grid} item xs={12}>
            {gapFundingStrategyTable.map((i) => (
              <MDTypography variant="body2" key={i.label}>
                {i.label}: $ {i.value}, {i.notes}
              </MDTypography>
            ))}
            <MDTypography variant="body2">
              Total Gap Funded : ${" "}
              {dealBundleWorkSheet.askPrice -
                calculateTotal(preClosingStrategyTable) -
                calculateTotal(gapFundingStrategyTable)}
            </MDTypography>
          </ZebraPrintBoxWarp>
          <MDBox px={3} pt={3}>
            <MDTypography variant="h6">Post-Closing Strategy</MDTypography>
          </MDBox>
          <ZebraPrintBoxWarp component={Grid} item xs={12}>
            {postClosingStrategyTable.map((i) => (
              <MDTypography variant="body2" key={i.label}>
                {i.label}: $ {i.value}, {i.notes}
              </MDTypography>
            ))}
            <MDTypography variant="body2">
              Total Cash Impact : $ {calculateTotal(postClosingStrategyTable, ["Y", "N"])}
            </MDTypography>
          </ZebraPrintBoxWarp>

          <ZebraPrintBoxWarp component={Grid} item xs={12}>
            <MDTypography variant="body2">
              Deal Notes: {dealBundleWorkSheet.dealNotes.notes}
            </MDTypography>
            <MDBox display="flex">
              <MDTypography flex={1} variant="body2">
                Business Impact: {dealBundleWorkSheet.dealNotes.bizImpact}
              </MDTypography>
              <MDTypography flex={1} variant="body2">
                Net Value Added: ${dealBundleWorkSheet.dealNotes.netValueAdded}
              </MDTypography>
            </MDBox>
            <MDBox display="flex">
              <MDTypography flex={1} variant="body2">
                Annual Positive Cash Added: ${dealBundleWorkSheet.dealNotes.annualPositiveCashAdded}
              </MDTypography>
              <MDTypography flex={1} variant="body2">
                Fully Funded or Partially Funded: $
                {dealBundleWorkSheet.dealNotes.fullyFundedOrPartiallyFunded}
              </MDTypography>
            </MDBox>
          </ZebraPrintBoxWarp>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default TermSheet;

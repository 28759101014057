/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import ReceiptIcon from "@mui/icons-material/Receipt";
import Divider from "@mui/material/Divider";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import Stack from "@mui/material/Stack";
import MDButton from "components/MDButton";
import { useFlatInject } from "store";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

function InvoiceList() {
  // get current user Payment status, expired date, etc
  const [user] = useFlatInject("user");
  const { user: userObject, listInvoices } = user;
  const { invoices } = user.user;

  useEffect(() => {
    listInvoices();
  }, [user.paymentStatus, user.vip, user.endAt]);

  return (
    <Card id="sessions">
      <MDBox p={3} lineHeight={1}>
        <MDBox mb={1}>
          <MDTypography variant="h5">Invoice</MDTypography>
        </MDBox>
      </MDBox>

      {invoices?.map((invoice) => (
        <MDBox pb={3} px={3} sx={{ overflow: "auto" }} key={invoice.id}>
          <Stack direction="column">
            <Stack direction="row" spacing={2}>
              {/* <MDTypography variant="button" fontWeight="light" textTransform="capitalize">
                {`start at: ${dayjs(Date(invoice?.created)).format("DD/MM/YYYY")}`}
              </MDTypography> */}
              <MDTypography variant="button" fontWeight="light" textTransform="capitalize">
                {`number: ${invoice.number}`}
              </MDTypography>
              <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
                <a
                  href={invoice.invoice_pdf}
                  style={{
                    color: "grey",
                    hover: "black",
                  }}
                >
                  download invoice
                </a>
              </MDTypography>
            </Stack>
          </Stack>
        </MDBox>
      ))}
    </Card>
  );
}

export default InvoiceList;

import { Box, MenuItem, Select, Stack } from "@mui/material";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { produce } from "immer";
import MDBox from "../../../../../../components/MDBox";
import MDTypography from "../../../../../../components/MDTypography";
import { useFlatInject } from "../../../../../../store";
import MDInput from "../../../../../../components/MDInput";
import MDEditor from "../../../../../../components/MDEditor";

const shareValuesMap = {
  shares: " shares ",
  assets: " assets ",
  sharesAndAssets: " share & asset ",
  other: "",
};

/**
 *
 * @param {{
 *  mode: 'view'|'edit',
 *  value: {
 *      date: string;
 *      between: string;
 *      sellerCompanyName: string;
 *      sellerCompanyNameShort: string;
 *      theirAddress: string;
 *      yourCompanyName: string;
 *      yourCompanyNameShort: string;
 *      yourCompanyLocation: string;
 *      yourCompanyAddress: string;
 *  }
 *  onChange: (value: {
 *      date: string;
 *      between: string;
 *      sellerCompanyName: string;
 *      sellerCompanyNameShort: string;
 *      theirAddress: string;
 *      yourCompanyName: string;
 *      yourCompanyNameShort: string;
 *      yourCompanyLocation: string;
 *      yourCompanyAddress: string;
 *  }) => any;
 * }} param0
 * @returns
 */
export default function FinalTermSheetContent({ mode = "view", value = {}, onChange, ...props }) {
  const [{ dealBundleWorkSheet, dealReview }] = useFlatInject("dealPlanOperation");

  const preClosingStrategyTable = dealBundleWorkSheet.preClosingStrategy.table.filter(
    (i) => i.yesOrNo === "Y" && i.name
  );
  const gapFundingStrategyTable = dealBundleWorkSheet.gapFundingStrategy.table.filter(
    (i) => i.yesOrNo === "Y" && i.name
  );
  const postClosingStrategyTable = dealBundleWorkSheet.postClosingStrategy.table.filter(
    (i) => i.yesOrNo === "Y" && i.name
  );

  const noCompanyValue = !value.yourCompanyName || !value.sellerCompanyName;

  return (
    <MDBox className="print-content" {...props}>
      <MDTypography variant="body2">
        {/* eslint-disable */}
        <MDBox display="flex" mb={2} alignItems="baseline">
          {mode === "view" ? (
            <MDTypography flex={1} fontSize="1rem">
              {`This Acknowledgement of the Term Sheet (the "Agreement") is made and effective on ${dayjs(
                value.date
              ).format("DD MMMM YYYY")}.`}
            </MDTypography>
          ) : (
            <>
              <MDBox flex="1" mr={1}>
                This Acknowledgement of the Term Sheet (the "Agreement") is made and effective on
              </MDBox>
              <MDBox
                component={DatePicker}
                sx={{ width: 230 }}
                format={"DD MMMM YYYY"}
                value={dayjs(value.date)}
                onChange={(v) =>
                  onChange?.(
                    produce(value, (draft) => {
                      draft.date = v.valueOf();
                    })
                  )
                }
              />
            </>
          )}
        </MDBox>
        <MDBox
          display="flex"
          mb={2}
          alignItems="baseline"
          sx={{
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <MDBox flex="none" mr={1}>
            BETWEEN:
          </MDBox>

          <Box
            sx={{
              marginTop: "1rem",
              display: "flex",
              gap: "10px",
              flexDirection: "column",
              flex: 1,
            }}
          >
            {mode === "view" ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                <MDBox sx={{}}>
                  {`${value.yourCompanyName} (${value.yourCompanyNameShort}), a corporation organised and existing under the laws of ${value.yourCompanyLocation}, with its head office address located at ${value.yourCompanyAddress}`}
                </MDBox>
              </Box>
            ) : (
              <>
                <Stack direction="row" spacing={2}>
                  <MDBox
                    component={MDInput}
                    placeholder="Buyer's Company Name"
                    width="100%"
                    value={value.yourCompanyName}
                    onChange={(e) =>
                      onChange?.(
                        produce(value, (draft) => {
                          draft.yourCompanyName = e.target.value;
                        })
                      )
                    }
                  />
                  <MDBox
                    component={MDInput}
                    placeholder="Abbreviated Buyer's Company Name"
                    width="100%"
                    value={value.yourCompanyNameShort}
                    onChange={(e) =>
                      onChange?.(
                        produce(value, (draft) => {
                          draft.yourCompanyNameShort = e.target.value;
                        })
                      )
                    }
                  />
                </Stack>
                <Stack direction="row" alignItems="baseline">
                  <MDBox
                    flex={1}
                    mx={1}
                  >{`a corporation organised and existing under the laws of`}</MDBox>
                  <MDBox
                    component={MDInput}
                    placeholder="Buyer's Company Location .eg: Victoria"
                    width="50%"
                    value={value.yourCompanyLocation}
                    onChange={(e) =>
                      onChange?.(
                        produce(value, (draft) => {
                          draft.yourCompanyLocation = e.target.value;
                        })
                      )
                    }
                  />
                </Stack>
                <Stack direction="row" alignItems="baseline">
                  <MDBox flex={1} mx={1}>{`with its head office address located at`}</MDBox>
                  <MDBox
                    component={MDInput}
                    placeholder="Buyer's Company Address"
                    width="50%"
                    value={value.yourCompanyAddress}
                    onChange={(e) =>
                      onChange?.(
                        produce(value, (draft) => {
                          draft.yourCompanyAddress = e.target.value;
                        })
                      )
                    }
                  />
                </Stack>
              </>
            )}
            <MDBox display="flex" mb={2} alignItems="baseline" marginTop="1rem">
              <MDBox flex="none" mr={1}>
                AND:
              </MDBox>
              {mode === "view" ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  <MDBox>{`${value.sellerCompanyName}, (${value.sellerCompanyNameShort}), individuals with office or residence at ${value.theirAddress}`}</MDBox>
                </Box>
              ) : (
                <Stack direction="column" width={"100%"}>
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems={"baseline"}
                    sx={{
                      width: "100%",
                      marginBottom: "0.7rem",
                    }}
                  >
                    <MDBox
                      component={MDInput}
                      placeholder="Seller's Company Name"
                      flex={1}
                      value={value.sellerCompanyName}
                      onChange={(e) =>
                        onChange?.(
                          produce(value, (draft) => {
                            draft.sellerCompanyName = e.target.value;
                          })
                        )
                      }
                    />
                    {/* <MDBox flex="none" mx={1}>
                    the{" "}
                  </MDBox> */}
                    <MDBox
                      component={MDInput}
                      placeholder="Abbreviated Seller's Company Name"
                      flex={1}
                      value={value.sellerCompanyNameShort}
                      onChange={(e) =>
                        onChange?.(
                          produce(value, (draft) => {
                            draft.sellerCompanyNameShort = e.target.value;
                          })
                        )
                      }
                    />
                  </Stack>
                  <Stack direction="row" alignItems="baseline">
                    <MDBox flex="none" mx={1}>
                      individuals with residence at{" "}
                    </MDBox>
                    {/* <MDBox flex={1} borderBottom="1px solid #344767"></MDBox> */}

                    <MDBox
                      component={MDInput}
                      placeholder="Seller's Company Address"
                      flex={1}
                      value={value.theirAddress}
                      onChange={(e) =>
                        onChange?.(
                          produce(value, (draft) => {
                            draft.theirAddress = e.target.value;
                          })
                        )
                      }
                    />
                  </Stack>
                </Stack>
              )}
            </MDBox>
          </Box>
        </MDBox>
        <MDBox mb={2}>
          {`NOW, THEREFORE, in consideration of the mutual agreements and covenants herein
              contained the Partners, hereby acknowledges that it has been retained by ${value.yourCompanyNameShort} for
              purposes of:`}
        </MDBox>

        <MDBox mb={2} fontWeight="bold">
          Purchasing
          {mode !== "view" ? (
            <Select
              value={value.shares}
              onChange={(e) => {
                onChange?.(
                  produce(value, (draft) => {
                    draft.shares = e.target.value;
                  })
                );
              }}
              sx={{
                marginLeft: "0.5rem",
                marginRight: "0.5rem",
              }}
            >
              <MenuItem value="shares">shares</MenuItem>
              <MenuItem value="assets">assets</MenuItem>
              <MenuItem value="sharesAndAssets">share & asset</MenuItem>
              <MenuItem value="other">other</MenuItem>
            </Select>
          ) : (
            shareValuesMap[value.shares]
          )}
          {mode !== "view"
            ? value.shares === "other" && (
                <MDInput
                  value={value.otherShares}
                  onChange={(e) =>
                    onChange?.(
                      produce(value, (draft) => {
                        draft.otherShares = e.target.value;
                      })
                    )
                  }
                />
              )
            : ` ${value.otherShares} `}
          in {value.sellerCompanyNameShort}
        </MDBox>
        <MDBox mb={2}>In consideration of the foregoing both parties agree as follows:</MDBox>
        <MDBox mb={2}>OVERALL TERMS:</MDBox>
        <MDBox mb={2} ml={2}>
          {mode !== "view" ? (
            <MDEditor
              value={value.overallTerms}
              onChange={(e) => {
                onChange?.(
                  produce(value, (draft) => {
                    draft.overallTerms = e;
                  })
                );
              }}
            />
          ) : (
            <div
              dangerouslySetInnerHTML={{ __html: value.overallTerms }}
              style={{
                marginLeft: "3rem",
              }}
            ></div>
          )}
          {/* <MDBox fontWeight="bold">PRE-CLOSING STRATEGY</MDBox>
          {preClosingStrategyTable.map((item, index) => (
            <MDBox key={item.name}>
              {index + 1}) {item.name?.labela\
            </MDBox>
          ))}
          <br />
          <MDBox fontWeight="bold">GAP FUNDING STRATEGY</MDBox>
          {gapFundingStrategyTable.map((item, index) => (
            <MDBox key={item.name}>
              {index + 1}) {item.name?.label}
            </MDBox>
          ))}
          <br />
          <MDBox fontWeight="bold">POST CLOSING STRATEGY</MDBox>
          {postClosingStrategyTable.map((item, index) => (
            <MDBox key={item.name}>
              {index + 1}) {item.name?.label}
            </MDBox>
          ))} */}
        </MDBox>
        {/* <MDBox mb={6}>
          &copy; Copyright Envision Corporation. 2002. All rights reserved. Protected by the
          copyright laws of the united States and Canada and by international treaties. IT IS
          ILLEGAL AND STRICTLY PROHIBITED TO DISTRIBUTE, PUBLISH, OFFER FOR SALE, LICENCE OR
          SUBLICENSE, GIVE OR DISCLOSE TO ANY OTHER PARTY, THIS PRODUCT IN HARD COPY OR DIGITAL
          FORM. ALL OFFENDERS WILL AUTOMATICALLY BE SUED IN A COURT OF LAW.
        </MDBox> */}
        <MDBox mb={6}>
          Upon signing, {value.sellerCompanyNameShort} agrees to help in any and all requests from{" "}
          {value.yourCompanyNameShort} for the Due Diligence period and to provide all information,
          to be accurate to the best of the Director's knowledge, in a timely manner.
        </MDBox>
        <MDBox mb={2}>
          IN WITNESS WHEREOF, the parties hereto have executed this Agreement as of the date above.
        </MDBox>

        <Stack direction="column" spacing={4}>
          <Stack direction="column" spacing={2}>
            {mode === "view" ? (
              <MDTypography fontWeight="bold" fontSize="medium">
                {value.yourCompanyName}
              </MDTypography>
            ) : (
              <MDTypography fontWeight="bold" fontSize="medium">
                {value.yourCompanyName}
              </MDTypography>
            )}
            <MDBox mb={6} borderBottom="1px solid #344767">
              Authorised Signature:
            </MDBox>
            <MDBox display="flex" mb={6}>
              <MDBox borderBottom="1px solid #344767" flex={1}>
                Name:
              </MDBox>
              <MDBox ml={"200px"} borderBottom="1px solid #344767" flex={1}>
                Date:
              </MDBox>
            </MDBox>
          </Stack>
          <Stack direction="column" spacing={2}>
            {mode === "view" ? (
              <MDTypography fontWeight="bold" fontSize="medium">
                {value.sellerCompanyName}
              </MDTypography>
            ) : (
              <MDTypography fontWeight="bold" fontSize="medium">
                {value.sellerCompanyName}
              </MDTypography>
            )}
            <MDBox mb={6} borderBottom="1px solid #344767">
              Authorised Signature:
            </MDBox>
            <MDBox display="flex" mb={6}>
              <MDBox borderBottom="1px solid #344767" flex={1}>
                Name:
              </MDBox>
              <MDBox ml={"200px"} borderBottom="1px solid #344767" flex={1}>
                Date:
              </MDBox>
            </MDBox>
          </Stack>
        </Stack>
      </MDTypography>
    </MDBox>
  );
}

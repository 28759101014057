import { IconButton, Paper, Stack } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useCallback, useState } from "react";
import { useFlatInject } from "store";
import { useLoading, useHttp } from "utils/hooks";
import { Message } from "utils/message";
import uploadFileIcon from "assets/images/logos/upload-file.svg";
import fileIcon from "assets/images/info.svg";
import pxToRem from "assets/theme/functions/pxToRem";

function DragDropFileUpload({ fileList }) {
  const [{ addFileThroughAWS }] = useFlatInject("dealPlanOperation");
  const [dragOver, setDragOver] = useState(false);

  const { run: handleFileUpload, loading } = useHttp(
    async (file) => {
      const fileSize = file.size;
      const fileName = file.name;
      const fileType = file.type;

      if (fileList.find((i) => i.name === fileName)) {
        Message.error("File is Existed.");
        return;
      }
      if (fileSize && fileSize > 20000000) {
        Message.error("File size is too big!");
        return;
      }
      await addFileThroughAWS(fileName, fileType, file);
    },
    {
      manual: true,
      genKeyByParams: ([e]) => e?.target?.files?.[0]?.name || "[]",
    }
  );

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
    setDragOver(true);
  }, []);

  const handleDragLeave = useCallback((event) => {
    event.preventDefault();
    setDragOver(false);
  }, []);

  const uploadFile = useCallback((event) => {
    const file = event.target.files[0];
    if (file) handleFileUpload(file);
  }, []);

  const handleDrop = useCallback((event) => {
    event.preventDefault();
    setDragOver(false);
    if (event.dataTransfer.items) {
      [...event.dataTransfer.items].forEach((item) => {
        if (item.kind === "file") {
          const file = item.getAsFile();
          if (file) handleFileUpload(file);
        }
      });
    }
  }, []);

  useLoading(loading);

  return (
    <MDBox>
      <MDTypography mb={1} variant="body2">
        Upload logo (optional)
      </MDTypography>
      <Paper
        variant="outlined"
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        style={{
          border: `1px dashed ${dragOver ? "#000" : "#aaa"}`,
          padding: 20,
          textAlign: "center",
          cursor: "pointer",
          background: dragOver ? "#eee" : "#fafafa",
          borderRadius: 12,
        }}
      >
        <label htmlFor="raised-button-file">
          <input type="file" multiple hidden id="raised-button-file" onChange={uploadFile} />
          <MDBox display="flex" flexDirection="column" alignItems="center">
            <IconButton color="primary" aria-label="upload picture" component="span">
              <MDBox component="img" src={uploadFileIcon} />
            </IconButton>
            <MDTypography variant="body2" color="green">
              Drag and drop or&nbsp;
              <span style={{ textDecoration: "underline" }}>click here</span> to choose files
            </MDTypography>
          </MDBox>
        </label>
      </Paper>
      <Stack direction="row" alignItems="center" gap={pxToRem(2)} mt={2}>
        <MDBox component="img" src={fileIcon} p={pxToRem(2)} />
        <MDTypography variant="caption">Upload requirements here</MDTypography>
      </Stack>
    </MDBox>
  );
}

export default DragDropFileUpload;

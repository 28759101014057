/**
 =========================================================
 * Otis Admin PRO - v2.0.1
 =========================================================
 
 * Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)
 
 Coded by www.creative-tim.com
 
 =========================================================
 
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";

// Otis Admin PRO React components
import MDBox from "components/MDBox";

// Settings page components
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useFlatInject } from "store";
import { useLocation, useHttp, useLoading } from "utils/hooks";
import React, { useEffect } from "react";
import Sidenav from "./components/Sidenav";
import Header from "./components/Header";

import DealOverview from "./components/DealOverview";
import DealAnalysis from "./components/DealAnalysis";
import DealStrategies from "./components/DealStrategies";
import DealBundleWorkSheet from "./components/DealBundleWorkSheet";
import DealSummery from "./components/DealSummery";
import TermSheet from "./components/TermSheet";
import DealDue from "./components/DealDue";
import SecondDealPitch from "./components/SecondDealPitch";
import FinalTermSheet from "./components/FinalTermSheet";
import FileUpload from "./components/FileUpload";
import ConclusiveNotes from "./components/ConclusiveNotes";

function DealPlanOperation() {
  const [{ step, updateId, fetchPlanData, initStates }] = useFlatInject("dealPlanOperation", {
    maps: ["step"],
  });

  const [{ printSelection }] = useFlatInject("dealPlanOperation");
  const location = useLocation();

  const { loading } = useHttp(
    async () => {
      if (location.query?.id) {
        updateId(Number(location.query?.id));
        await fetchPlanData();
      }
    },
    { deps: [location.query?.id] }
  );

  useLoading(loading);

  if (loading) {
    return null;
  }

  const planStepsUI = [
    <Grid item xs={12} className="no-print">
      <DealOverview />
    </Grid>,
    <Grid item xs={12} className="no-print">
      <DealAnalysis />
    </Grid>,
    // <Grid item xs={12} className="no-print">
    //   <DealStrategies />
    // </Grid>,
    <Grid item xs={12} className="no-print">
      <DealBundleWorkSheet />
    </Grid>,
    <Grid item xs={12} className="no-print">
      <DealSummery />
    </Grid>,
    <Grid item xs={12} className={printSelection !== "v1" && "no-print"}>
      <TermSheet />
    </Grid>,
    <Grid item xs={12} className="no-print">
      <DealDue />
    </Grid>,
    // <Grid item xs={12} className="no-print">
    //   <SecondDealPitch />
    // </Grid>,
    <Grid item xs={12} className={printSelection !== "v2" && "no-print"}>
      <FinalTermSheet />
    </Grid>,
    <Grid item xs={12} className="no-print">
      <ConclusiveNotes />
    </Grid>,
    <Grid item xs={12} className="no-print">
      <FileUpload />
    </Grid>,
    // eslint-disable-next-line react/no-array-index-key
  ].map((i, index) => React.cloneElement(i, { key: index }));

  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />
      <MDBox
        mt={10}
        sx={{
          "@media print": {
            margin: "0",
            size: "auto",
          },
          width: "fit-content",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} lg={2} className="no-print">
            <Sidenav />
          </Grid>
          <Grid item xs={12} lg={10}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                {planStepsUI.filter((_, index) => index < step).map((s, index) => s)}
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default DealPlanOperation;

import React from "react";
import MDTypography from "components/MDTypography";

function TitleCompSignUp() {
  return (
    <MDTypography
      fontSize="32px"
      fontWeight="regular"
      sx={{
        color: "#282828",
      }}
    >
      Get started
    </MDTypography>
  );
}

export default TitleCompSignUp;

import axios from "axios";
import { HttpError } from "utils/HttpError";
import { setToken, getUserId, getToken } from "../utils/auth";
// import { Message } from "utils/message";
import { messageSubject } from "../utils/common-event";

/**
 * @type {{
 *  get(...arg: Parameters<import("axios").AxiosInstance["post"]>): Promise<{code: number; data: any; message: string}>;
 *  post(...arg: Parameters<import("axios").AxiosInstance["post"]>): Promise<{code: number; data: any; message: string}>;
 * }}
 */
export const http = axios.create({
  timeout: 1000 * 60,
  baseURL: "https://api.dev.sansoni.pgtest.co",
});

http.interceptors.request.use(
  (config) => {
    config.headers.token = getToken();
    config.headers["user-id"] = getUserId();
    config.headers.user_id = getUserId();
    return config;
  },
  (err) => {
    messageSubject.next({ message: err.message, type: "error" });
    throw err;
  }
);

http.interceptors.response.use(
  (response) => {
    // 如果是未登录或者登陆错误状态, 则跳转到登录页面
    if ([401004, 401005].includes(response?.data?.code)) {
      const { pathname, search } = window.location;
      const redirect = pathname + search;
      if (pathname !== "/sign-in") {
        window.location.href = `/sign-in?redirect=${redirect}`;
      }
      throw new HttpError(response.data.msg, Number(response.data.code));
    }
    if (response.data.code !== 0) {
      messageSubject.next({ message: response.data.msg, type: "error" });
      throw new HttpError(response.data.msg, Number(response.data.code));
    }
    return response.data;
  },
  (err) => {
    messageSubject.next({ message: err.message, type: "error" });
    throw new HttpError(err.message || "network error");
  }
);

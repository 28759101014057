import Autocomplete from "@mui/material/Autocomplete";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import { ErrorMessage, useField, useFormikContext } from "formik";

import {
  preCloseStrategies,
  postCloseStrategies,
  gapFundingStrategies,
} from "../../../../../constants/strategies";

const strategiesMap = {
  preCloseStrategies,
  postCloseStrategies,
  gapFundingStrategies,
};

const labelMap = {
  preCloseStrategies: "PRE-CLOSING STRATEGIES",
  postCloseStrategies: "POST-CLOSING STRATEGIES",
  gapFundingStrategies: "GAP FUNDING STRATEGIES",
};

export default function StrategiesSelect({ name, type = "preCloseStrategies", sx }) {
  const [field] = useField(name);
  const { setFieldValue } = useFormikContext();
  return (
    <>
      <Autocomplete
        sx={sx}
        name={name}
        getOptionLabel={(option) => option?.value || ""}
        renderInput={(params) => (
          <MDInput {...params} name={name} variant="standard" label={labelMap[type]} />
        )}
        value={field.value}
        onChange={(_, v) => {
          setFieldValue(name, v);
        }}
        options={strategiesMap[type]}
        renderOption={(props, option, state) => (
          <MDBox {...props} flexWrap="wrap">
            <MDBox width="100%">{option.label}</MDBox>
            <MDBox fontSize={8} color="secondary">
              {option.description}
            </MDBox>
          </MDBox>
        )}
      />
      <MDBox color="error.main" fontSize={14}>
        <ErrorMessage name={name} />
      </MDBox>
    </>
  );
}

// react and Data
import React, { useState, useEffect } from "react";
import { useFlatInject } from "store";

// Otis Admin PRO React components
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";

// mui native components
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "500px",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
};

export default function UpgradeFromTermSheet() {
  // prepare for state mutation
  const [user] = useFlatInject("user");
  const { user: userObject, upgradePayment } = user;

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <MDButton
        color="warning"
        onClick={() => {
          handleOpen();
        }}
      >
        Upgrade
      </MDButton>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Box display="flex" justifyContent="center" alignItems="center" mt="50px">
              <Stack justifyContent="center">
                <MDTypography id="transition-modal-title" variant="h6" component="h2">
                  Upgrade to Premium at anytime, and enjoy the full features of Deal dashboard.
                </MDTypography>
                <MDTypography id="transition-modal-description" sx={{ mt: 2, fontSize: "1rem" }}>
                  Payment will be made immediately, even during your current subscription period,
                  fee will be caculated pro rata.
                </MDTypography>
                <Stack mt={10} spacing={2} direction="row" justifyContent="center">
                  <MDButton
                    color="warning"
                    onClick={() => {
                      upgradePayment();
                      handleClose();
                    }}
                  >
                    Confirm Upgrade
                  </MDButton>
                  <MDButton
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Cancel Upgrade
                  </MDButton>
                </Stack>
              </Stack>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

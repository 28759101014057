import axios from "axios";
import { setToken, getUserId, getToken } from "utils/auth";
import { HttpError } from "utils/HttpError";
// import { Message } from "utils/message";
import { messageSubject } from "../utils/common-event";

const downloadFileHttp = axios.create({
  timeout: 1000 * 60,
  baseURL: "/api",
  responseType: "blob",
});

downloadFileHttp.interceptors.request.use(
  (config) => {
    config.headers.token = getToken();
    config.headers.user_id = getUserId();

    return config;
  },
  (err) => {
    messageSubject.next({ message: err.message, type: "error" });
    throw err;
  }
);
downloadFileHttp.interceptors.response.use(
  (response) => response,
  (err) => {
    messageSubject.next({ message: err.message, type: "error" });
    throw new HttpError(err.message || "network error");
  }
);

export const downLoadFile = (file) => {
  // 以之前的post请求举例
  downloadFileHttp
    .get(file.url)
    .then((res) => {
      const { data, headers } = res;
      const fileName = file.name;
      // 此处当返回json文件时需要先对data进行JSON.stringify处理，其他类型文件不用做处理
      // const blob = new Blob([JSON.stringify(data)], ...)
      const blob = new Blob([data], { type: headers["content-type"] });
      const dom = document.createElement("a");
      const url = window.URL.createObjectURL(blob);
      dom.href = url;
      dom.download = decodeURI(fileName);
      dom.style.display = "none";
      document.body.appendChild(dom);
      dom.click();
      dom.parentNode.removeChild(dom);
      window.URL.revokeObjectURL(url);
    })
    .catch((err) => {});
};

/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { MoneyInput } from "components/MoneyInput";
import Autocomplete from "@mui/material/Autocomplete";
import { RadioGroup, Radio, FormControlLabel, Stack } from "@mui/material";
import React, { forwardRef, useCallback, useEffect, useMemo, useRef } from "react";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

// Data
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDRadio from "components/MDRadio";
import Table from "components/Table";
import { useFlatInject } from "store";
import { isNaN, omit } from "lodash";
import { Message } from "utils/message";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm, Form, FormItem } from "../../../../../components/Form";

const radioUI = <MDRadio />;

const fieldStyle = { flex: 1, marginRight: 8 };
const labelSx = { display: "none" };

const inputInsteadOfMoney = ["aksMultiple", "reasonsForSelling", "socialMediaFollowing", "ipOwned"];

const BusinessOverviewForm = React.forwardRef((props, ref) => {
  const [dealPlanOperation] = useFlatInject("dealPlanOperation");
  const form = useForm({
    initialValues: {
      businessOverview: dealPlanOperation.dealAnalysis.businessOverviewForm,
    },
  });

  React.useImperativeHandle(ref, () => ({
    getFormData: () => form.values,
    validate: () => form.validateForm(),
  }));

  const columns = useMemo(
    () => [
      {
        key: "businessOverview",
        title: <MDTypography fontSize="12px">Business Overview</MDTypography>,
        dataIndex: "label",
        width: "30%",
        render: (rowData, index) => (
          <MDBox>
            <MDTypography variant="body2">{rowData.label}</MDTypography>
            <MDBox color="#ccc">{rowData.description || "description"}</MDBox>
          </MDBox>
        ),
      },
      {
        key: "yesOrNo",
        title: <MDTypography fontSize="12px">Yes / No</MDTypography>,
        width: "20%",
        render: (rowData, index) => {
          const notShowList = [""];
          return (
            <FormItem as={RadioGroup} row name={`businessOverview[${index}].yesOrNo`}>
              <Stack direction="row">
                <FormControlLabel
                  value="Y"
                  control={radioUI}
                  label={<MDTypography variant="subtitle2">Yes</MDTypography>}
                />
                <FormControlLabel
                  value="N"
                  control={radioUI}
                  label={<MDTypography variant="subtitle2">No</MDTypography>}
                />
              </Stack>
            </FormItem>
          );
        },
      },
      {
        key: "value",
        title: <MDTypography fontSize="12px">Value</MDTypography>,
        width: "10%",
        render: (rowData, index) => {
          console.log();
          return (
            form.values.businessOverview[index].yesOrNo === "Y" && (
              // <FormItem as={MDInput} type="number" name={`businessOverview[${index}].value`} />
              <FormItem
                variant="standard"
                as={MoneyInput}
                type="number"
                name={`businessOverview[${index}].value`}
              />
            )
          );
        },
      },
      {
        key: "notes",
        title: <MDTypography fontSize="12px">Notes</MDTypography>,
        render: (rowData, index) => (
          <FormItem
            variant="standard"
            sx={{ width: "100%" }}
            placeholder="enter note here"
            as={MDInput}
            name={`businessOverview[${index}].notes`}
          />
        ),
      },
    ],
    [form.values.businessOverview]
  );
  return (
    <Form form={form} fieldStyle={fieldStyle} labelSx={labelSx}>
      <Table px={0} columns={columns} data={dealPlanOperation.dealAnalysis.businessOverviewForm} />
    </Form>
  );
});

const DealOverviewForm = React.forwardRef((props, ref) => {
  const [dealPlanOperation] = useFlatInject("dealPlanOperation");
  const [{ dealReview, saveDealReview, nextStep }] = useFlatInject("dealPlanOperation");

  const form = useForm({
    initialValues: {
      dealOverviewForm: dealPlanOperation.dealAnalysisDealOverviewTable,
    },
  });
  React.useImperativeHandle(ref, () => ({
    getFormData: () => form.values,
    validate: () => form.validateForm(),
  }));

  useEffect(() => {
    const askPrice = form.values.dealOverviewForm[0].value;
    const profit = form.values.dealOverviewForm[1].value;
    const askMultiple = (askPrice / profit).toFixed(2); // 保留两位小数
    form.setFieldValue("dealOverviewForm[2].value", askMultiple);

    // TODO: set Motivation to sell
    // form.setFieldValue("dealOverviewForm[15].notes", dealReview.motivationToSell);
  }, [form.values.dealOverviewForm[0].value, form.values.dealOverviewForm[1].value]);

  const columns2 = useMemo(
    () => [
      {
        key: "dealOverview",
        title: <MDTypography fontSize="12px">Deal Overview</MDTypography>,
        width: "30%",
        render: (rowData, index) => (
          <MDBox>
            <MDTypography variant="body2">{rowData.label}</MDTypography>
            <MDBox color="#ccc">{rowData.description || "description"}</MDBox>
          </MDBox>
        ),
      },
      {
        key: "yesOrNo",
        title: <MDTypography fontSize="12px">Yes / No</MDTypography>,
        width: "20%",
        render: (rowData, index) => {
          // console.log(rowData);
          const notShowList = ["askPrice", "profit", "aksMultiple", "reasonsForSelling"];
          if (notShowList.includes(rowData?.key)) {
            return;
          }
          return (
            <FormItem as={RadioGroup} row name={`dealOverviewForm[${index}].yesOrNo`}>
              <Stack direction="row">
                <FormControlLabel
                  value="Y"
                  control={radioUI}
                  label={<MDTypography variant="subtitle2">Yes</MDTypography>}
                />
                <FormControlLabel
                  value="N"
                  control={radioUI}
                  label={<MDTypography variant="subtitle2">No</MDTypography>}
                />
              </Stack>
            </FormItem>
          );
        },
      },
      {
        key: "value",
        title: <MDTypography fontSize="12px">Value</MDTypography>,
        width: "10%",
        render: (rowData, index) => {
          if (rowData.key === "reasonsForSelling") {
            return;
          }
          if (form.values.dealOverviewForm[index].yesOrNo === "Y") {
            // exclude form tables are not money
            if (inputInsteadOfMoney.includes(rowData?.key)) {
              return (
                <FormItem
                  variant="standard"
                  as={MDInput}
                  type="number"
                  name={`dealOverviewForm[${index}].value`}
                />
              );
            }
            // caculate aksMultiple => askPrice / profit
            if (rowData?.key === "aksMultiple") {
              return (
                <FormItem
                  as={MDInput}
                  type="number"
                  variant="standard"
                  name={`dealOverviewForm[${index}].value`}
                  disabled
                />
              );
            }
            // unable to bind the value of the select
            return (
              <FormItem
                variant="standard"
                as={MoneyInput}
                type="number"
                name={`dealOverviewForm[${index}].value`}
              />
            );
          }
        },
      },
      {
        key: "notes",
        title: <MDTypography fontSize="12px">Notes</MDTypography>,
        render: (rowData, index) => (
          <FormItem
            variant="standard"
            sx={{ width: "100%" }}
            as={MDInput}
            placeholder="enter note here"
            name={`dealOverviewForm[${index}].notes`}
          />
        ),
      },
    ],
    [form.values.dealOverviewForm]
  );
  return (
    <Form form={form} fieldStyle={fieldStyle} labelSx={labelSx}>
      <Table px={0} columns={columns2} data={dealPlanOperation.dealAnalysisDealOverviewTable} />
    </Form>
  );
});

const DealNotesForm = forwardRef((props, ref) => {
  const [dealPlanOperation] = useFlatInject("dealPlanOperation");
  const form = useForm({
    initialValues: {
      dealNotes: dealPlanOperation.dealAnalysis.dealNotes,
    },
    onSubmit: props.submit,
  });
  React.useImperativeHandle(ref, () => ({
    getFormData: () => form.values,
    validate: () => form.validateForm(),
  }));
  const navigate = useNavigate();
  return (
    <Form form={form} fieldStyle={fieldStyle} labelSx={labelSx}>
      <MDBox pt={3}>
        <MDTypography variant="h6" fontWeight="regular">
          Other deal notes?
        </MDTypography>
      </MDBox>
      <MDBox xs={12} mt={1}>
        <FormItem
          as={MDInput}
          multiline
          type="text"
          name="dealNotes"
          variant="standard"
          placeholder="enter here"
          fullWidth
        />
      </MDBox>
      <Grid mt={4} item xs={12} textAlign="right">
        <MDButton
          variant="outlined"
          onClick={() => navigate("/deal-planner", { replace: true })}
          color="green"
          sx={{ mr: 1 }}
        >
          Cancel
        </MDButton>
        <MDButton
          loading={form.isSubmitting}
          disabled={form.isSubmitting || !dealPlanOperation.dealReview.businessName}
          onClick={form.submitForm}
          color="green"
        >
          Save and next
        </MDButton>
      </Grid>
    </Form>
  );
});

function DealAnalysis() {
  const [{ saveDealAnalysis }] = useFlatInject("dealPlanOperation");
  const location = useLocation();
  const bizOverviewRef = useRef();
  const dealOverviewRef = useRef();
  const dealNotesRef = useRef();
  const submit = useCallback(async () => {
    const bizOverviewFormData = bizOverviewRef.current?.getFormData?.();
    const dealOverviewFormData = dealOverviewRef.current?.getFormData?.();
    const dealNotesRefFormData = dealNotesRef.current?.getFormData?.();
    try {
      await saveDealAnalysis({
        businessOverviewForm: bizOverviewFormData.businessOverview,
        dealOverviewForm: dealOverviewFormData.dealOverviewForm,
        dealNotes: dealNotesRefFormData.dealNotes,
      });
      window.location.href = `${location.pathname + location.search}#deal-bundle-work-sheet`;
      Message.success("save success!");
    } catch (error) {
      Message.error(error.message);
    }
  }, [saveDealAnalysis]);

  return (
    <Card id="deal-analysis" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h4">Deal Analysis</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <BusinessOverviewForm ref={bizOverviewRef} />
        <MDBox py={2}>{}</MDBox>
        <DealOverviewForm ref={dealOverviewRef} />
        <DealNotesForm submit={submit} ref={dealNotesRef} />
      </MDBox>
    </Card>
  );
}

export default DealAnalysis;

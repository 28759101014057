/**
 =========================================================
 * Otis Admin PRO - v2.0.1
 =========================================================

 * Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useEffect } from "react";

// react-router-dom components
import { useLocation, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Otis Admin PRO React components
import MDBox from "components/MDBox";

// Otis Admin PRO React context
import { useMaterialUIController } from "utils/hooks";
import { useFlatInject, useInject } from "store";

function DashboardLayout({ children }) {
  const [controller, { setLayout }] = useMaterialUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();
  const [user] = useInject("user");
  const [{ user: userFlat, loading }] = useFlatInject("user", {
    state: [(s) => s.user.paymentStatus],
  });
  useEffect(() => {
    setLayout("dashboard");
  }, [pathname]);
  const navigate = useNavigate();
  // eslint-disable-next-line no-shadow
  const checkPaymentInfo = async (user, userFlat, loading) => {
    const info = await user.actions.fetchPaymentInfo();
    if (
      ["open", "visitor"].includes(userFlat.paymentStatus) &&
      loading.fetchingPaymentInfo === false &&
      userFlat.id &&
      (!info?.data?.endAt || Number.isNaN(Number(info?.data?.endAt)) || !Number(info?.data?.endAt))
    ) {
      console.log("sssss");
      navigate("/pay");
    }
  };
  useEffect(() => {
    checkPaymentInfo(user, userFlat, loading);
  }, [userFlat.id, loading.fetchingPaymentInfo, userFlat.paymentStatus]);

  if (user.paymentStatus === "" || loading.fetchingPaymentInfo) {
    return (
      <MDBox width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
        loading...
      </MDBox>
    );
  }

  return (
    <MDBox
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        p: 3,
        position: "relative",

        [breakpoints.up("xl")]: {
          marginLeft: miniSidenav ? pxToRem(80) : pxToRem(260),
          transition: transitions.create(["margin-left", "margin-right"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
        [breakpoints.up("md")]: {
          width: "auto",
        },
        [breakpoints.up("xs")]: {
          width: "fit-content",
        },
      })}
    >
      {children}
    </MDBox>
  );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;

/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  All of the routes for the Otis Admin PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav.
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Otis Admin PRO React layouts
import Analytics from "layouts/dashboards/analytics";
import Sales from "layouts/dashboards/sales";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import AllProjects from "layouts/pages/profile/all-projects";
import NewUser from "layouts/pages/users/new-user";
import Settings from "pages/settings";
import Billing from "layouts/pages/account/billing";
import Invoice from "layouts/pages/account/invoice";
import Timeline from "layouts/pages/projects/timeline";
// import PricingPage from "pages/pay";
import PricingPage from "layouts/pages/pricing-page";
import Widgets from "layouts/pages/widgets";
import RTL from "layouts/pages/rtl";
import Charts from "layouts/pages/charts";
import Notifications from "layouts/pages/notifications";
import Kanban from "layouts/applications/kanban";
import Wizard from "layouts/applications/wizard";
import DataTables from "layouts/applications/data-tables";
import Calendar from "layouts/applications/calendar";
import NewProduct from "layouts/ecommerce/products/new-product";
import EditProduct from "layouts/ecommerce/products/edit-product";
import ProductPage from "layouts/ecommerce/products/product-page";
import OrderList from "layouts/ecommerce/orders/order-list";
import OrderDetails from "layouts/ecommerce/orders/order-details";
import SignInBasic from "layouts/authentication/sign-in/basic";

import ResetCover from "layouts/authentication/reset-password/cover";

// Otis Admin PRO React components
import MDAvatar from "components/MDAvatar";
import SignIn from "pages/sign-in/illustration";
import Logout from "pages/logout";
import SignUp from "pages/sign-up";
import DealPlannerList from "pages/deal-plan/list";
// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/team-3.jpg";
import DealStrategies from "pages/deal-plan/deal-strategies";
import DealDashboardIcon from "assets/images/small-logos/deal-dashboard-icon.svg";
import DealStrategiesIcon from "assets/images/small-logos/deal-strategies-icon.svg";
import DealStrategiesActiveIcon from "assets/images/small-logos/deal-strategies-active.svg";
import DealDashboardActiveIcon from "assets/images/small-logos/deal-dashboard-active.svg";
import DealPlanOperation from "./pages/deal-plan/create-and-edit";

const routes = [
  {
    name: "Sign In",
    key: "sign-in",
    route: "/sign-in",
    component: <SignIn />,
  },
  {
    name: "Sign Up",
    key: "sign-up",
    route: "/sign-up",
    component: <SignUp />,
  },
  {
    name: "Pricing Page",
    key: "pricing-page",
    route: "/pay",
    component: <PricingPage />,
  },
  {
    name: "Create a deal",
    key: "create-a-deal",
    route: "/create-a-deal",
    component: <DealPlanOperation />,
  },
  {
    name: "Edit a deal",
    key: "edit-a-deal",
    route: "/edit-a-deal",
    component: <DealPlanOperation />,
  },
  {
    type: "collapse",
    name: "username",
    key: "username",
    icon: <MDAvatar alt="username" size="sm" />,
    collapse: [
      {
        name: "My Profile",
        key: "my-profile",
        route: "/my-profile",
        component: <Settings />,
      },
    ],
  },
  {
    type: "collapse-nav",
    name: "Deal Dashboard",
    key: "deal-planner-menu",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Deal Dashboard",
        key: "deal-planner",
        route: "/deal-planner",
        iconInactive: DealDashboardIcon,
        iconActive: DealDashboardActiveIcon,
        component: <DealPlannerList />,
      },
      {
        name: "Deal Strategies",
        key: "deal-strategies",
        route: "/deal-strategies",
        iconInactive: DealStrategiesIcon,
        iconActive: DealStrategiesActiveIcon,
        component: <DealStrategies />,
      },
    ],
  },
  // {
  //   name: "Deal Strategies",
  //   key: "deal-strategies",
  //   icon: <Icon fontSize="medium">dashboard</Icon>,
  //   route: "/deal-strategies",
  //   component: <DealPlanOperation />,
  // },
];

export default routes;

import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import MuiTable from "@mui/material/Table";
import Pagination from "@mui/material/Pagination";
import * as React from "react";
import TableContainer from "@mui/material/TableContainer";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import MDBox from "components/MDBox";
import { css } from "@emotion/css";
import MDTypography from "components/MDTypography";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "components/MDInput";
import { omit } from "lodash";

function Table({ data = [], columns, pagination, onTableChange, children, px }) {
  return (
    <TableContainer>
      <MuiTable size="small">
        <TableHead component="thead">
          <TableRow>
            {columns.map((c) => (
              <DataTableHeadCell
                width={c.width ? c.width : "auto"}
                align={c.align ? c.align : "left"}
                key={c.key}
                px={px}
                sorted={false}
              >
                {c.title}
              </DataTableHeadCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={row.id || index}>
              {columns.map((c) => {
                const colspan =
                  typeof c.colSpan === "function" ? c.colSpan(row, index, data) : c.colSpan;
                if (colspan === "0") {
                  return null;
                }
                return (
                  <DataTableBodyCell
                    width={c.width}
                    align={c.align ? c.align : "left"}
                    key={c.key}
                    px={px}
                    colSpan={colspan}
                    rowSpan={
                      typeof c.rowSpan === "function" ? c.rowSpan(row, index, data) : c.rowSpan
                    }
                    {...omit(
                      c,
                      "width",
                      "align",
                      "key",
                      "title",
                      "render",
                      "dataIndex",
                      "rowSpan",
                      "colSpan"
                    )}
                  >
                    {c.render
                      ? c.render?.(row, index, data)
                      : c.dataIndex
                      ? row[c.dataIndex]
                      : null}
                  </DataTableBodyCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>
      {pagination && (
        <MDBox display="flex" p={3}>
          <MDBox display="flex" alignItems="center">
            <MDTypography
              variant="button"
              sx={{ display: "flex", alignItems: "center" }}
              color="secondary"
              fontWeight="regular"
            >
              Showing 1 to 10 of {data.length} entries
            </MDTypography>
            <MDBox display="flex" alignItems="center" ml={2}>
              <Autocomplete
                disableClearable
                options={["5", "10", "15", "20", "25"]}
                size="small"
                sx={{ width: "5rem" }}
                value={String(pagination.pageSize)}
                onChange={(e, v) => onTableChange?.({ ...pagination, pageSize: v })}
                renderInput={(params) => <MDInput {...params} />}
              />
              <MDTypography variant="caption" color="secondary">
                &nbsp;&nbsp;entries per page
              </MDTypography>
            </MDBox>
          </MDBox>

          <Pagination
            sx={{ ml: "auto" }}
            classes={{
              root: css`
                justify-content: flex-end;
                display: flex;
              `,
            }}
            page={pagination.pageNum}
            onChange={(_, v) => onTableChange?.({ ...pagination, pageNum: v })}
            count={Math.floor(pagination.total / pagination.pageSize)}
            variant="outlined"
            color="primary"
          />
        </MDBox>
      )}
      {children}
    </TableContainer>
  );
}

export { Table };
export default Table;

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "components/MDButton";
import React, { useCallback, useMemo, useRef, useState } from "react";
import ReactDOM from "react-dom/client";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Slide from "@mui/material/Slide";
import ErrorIcon from "@mui/icons-material/Error";
import { sleep } from "utils";
import theme from "assets/theme";

import styles from "./style.module.scss";

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

function MyDialog({ title, content, cancelText, confirmText, cancel, confirm }) {
  const [open, setOpen] = useState(true);
  const [isConfirm, setIsConfirm] = useState(false);
  const localCancel = useCallback(async () => {
    setOpen(false);
    setIsConfirm(false);
  }, []);

  const localConfirm = useCallback(async () => {
    setOpen(false);
    setIsConfirm(true);
  }, []);

  return (
    <Dialog
      open={open}
      onClose={localCancel}
      classes={styles}
      TransitionComponent={Transition}
      TransitionProps={useMemo(
        () => ({
          onExited: async () => {
            await sleep();
            if (isConfirm) {
              confirm();
            } else {
              cancel();
            }
          },
        }),
        [cancel, confirm, isConfirm]
      )}
      keepMounted
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ display: "flex", alignItems: "center" }}>
        <ErrorIcon color="warning" sx={{ mr: 1 }} />
        {title}
      </DialogTitle>
      <DialogContent>
        {/* <DialogContentText id="alert-dialog-description">{content}</DialogContentText> */}
        {content}
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Button variant="contained" color="dark" onClick={localConfirm} autoFocus>
          {confirmText}
        </Button>
        <Button variant="outlined" color="info" onClick={localCancel}>
          {cancelText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function ConfirmDialog({ title, content, cancelText = "Cancel", confirmText = "Confirm" }) {
  return new Promise((resolve, reject) => {
    const ele = document.createElement("div");
    document.body.appendChild(ele);
    const root = ReactDOM.createRoot(ele);

    const cancel = () => {
      root.unmount();
      document.body.removeChild(ele);
      // eslint-disable-next-line prefer-promise-reject-errors
      reject(false);
    };
    const confirm = () => {
      root.unmount();
      document.body.removeChild(ele);
      resolve(true);
    };
    root.render(
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <MyDialog
          confirm={confirm}
          confirmText={confirmText}
          title={title}
          content={content}
          cancelText={cancelText}
          cancel={cancel}
        />
      </ThemeProvider>
    );
  });
}

/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import { useLocation, useNavigate } from "react-router-dom";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";
import { isEmpty } from "lodash";
import { useHttp } from "utils/hooks";
import { Message } from "utils/message";

// Data
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDEditor from "components/MDEditor";
import { useFlatInject } from "store";
import React, { useState, useEffect } from "react";
import { useForm, Form, FormItem } from "../../../../../components/Form";
import { ZebraPrintBoxWarp } from "../DealSummery";

const initValue = () => ({
  note: "",
});

function ConclusiveNotes() {
  const [{ dealReview, saveConclusiveNotes, conclusiveNotes }] = useFlatInject("dealPlanOperation");
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState(
    isEmpty(conclusiveNotes)
      ? initValue()
      : {
          ...initValue(),
          ...conclusiveNotes,
        }
  );
  const submitForm = async () => {
    await saveConclusiveNotes(value);
    Message.success("Save success!");
  };
  const { run, loading } = useHttp(submitForm, { manual: true });

  return (
    <Card id="second-deal-pitch" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h4">Deal memo</MDTypography>
      </MDBox>
      <MDBox pb={3} px={3}>
        <Stack direction="column" spacing={2} className="no-print">
          <MDEditor
            sx={{
              width: "100%",
            }}
            value={value.note}
            onChange={(e) => {
              setValue({ ...value, note: e });
            }}
          />
          <MDBox pb={3} py={3} className="no-print">
            <Grid item xs={12} textAlign="right">
              <MDButton
                color="green"
                variant="outlined"
                onClick={() => navigate("/deal-planner", { replace: true })}
                sx={{ mr: 1 }}
              >
                Cancel
              </MDButton>
              <MDButton
                color="green"
                disabled={loading || !dealReview.businessName}
                loading={loading}
                onClick={() => run()}
              >
                Save and next
              </MDButton>
            </Grid>
          </MDBox>
        </Stack>
      </MDBox>
    </Card>
  );
}

export default ConclusiveNotes;

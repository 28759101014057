/* eslint-disable no-restricted-globals */
/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { MenuItem, Grid, Select, Tooltip } from "@mui/material";
import Card from "@mui/material/Card";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

// Otis Admin PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Data
import { useFlatInject } from "store";
import { Table } from "components/Table";
import Icon from "@mui/material/Icon";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { Modal } from "utils/modal";
import { useHttp, useLoading } from "../../../utils/hooks";
import MDButton from "../../../components/MDButton";

function DataTables() {
  const [dealPlanner] = useFlatInject("dealPlanner");
  const [{ updateId, resetDealPlanState }] = useFlatInject("dealPlanOperation");

  const { fetchDealPlanList, pageInfo, tableData, updateName, deleteDealPlan, updateIsArchived } =
    dealPlanner;

  const { loading, run: fetchDealPlanListProxy } = useHttp(fetchDealPlanList, {
    single: true,
    deps: [pageInfo.pageNum, pageInfo.pageSize, pageInfo.isArchived],
  });
  const { run } = useHttp(fetchDealPlanListProxy, {
    debounceTime: 1500,
    manual: true,
  });
  const { loading: deleting, run: deleteDealPlanProxy } = useHttp(deleteDealPlan, {
    single: true,
    manual: true,
  });

  const navigate = useNavigate();
  // useLoading(loading || deleting);

  const columns = [
    {
      key: "targetBusiness",
      title: "Target Business",
      dataIndex: "targetBusiness",
      render: (data) => {
        const title = data ? JSON.parse(data.dealReview).businessName : "";
        return (
          <MDButton
            onClick={() => {
              navigate(`/edit-a-deal?id=${data.id}`);
            }}
          >
            {title}
          </MDButton>
        );
      },
    },
    {
      key: "createdAt",
      title: "Create Time",
      dataIndex: "createdAt",
      render: (data) => <span>{dayjs(data.createdAt * 1000).format("DD/MM/YYYY")}</span>,
    },
    {
      key: "actions",
      title: "Actions",
      render: (data) => (
        <MDBox>
          <Tooltip title={data.isArchived ? "Active" : "Archive"} placement="top-start">
            <MDButton
              size="small"
              variant="gradient"
              sx={{ ml: "auto", mr: 1 }}
              iconOnly
              color="info"
              onClick={async () => {
                updateIsArchived(data.isArchived ? 0 : 1, data.id);
              }}
            >
              <Icon fontSize="medium">feed</Icon>
            </MDButton>
          </Tooltip>
          <Tooltip title="Delete" placement="top-start">
            <MDButton
              size="small"
              variant="gradient"
              sx={{ ml: "auto", mr: 0 }}
              iconOnly
              color="error"
              onClick={async () => {
                await Modal.confirm({
                  title: "Warining",
                  content: "Are your sure to delete this plan?",
                });
                await deleteDealPlanProxy(data.id);
              }}
            >
              <Icon fontSize="medium">delete</Icon>
            </MDButton>
          </Tooltip>
        </MDBox>
      ),
    },
  ];

  useLoading(loading);

  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />
      <MDBox pb={3} mt={10}>
        <Card sx={{ pt: 3 }}>
          <MDBox display="flex" pl={3} mb={2}>
            <MDInput
              value={pageInfo.name}
              onChange={(e) => {
                updateName(e.target.value);
                run();
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  fetchDealPlanListProxy();
                }
              }}
              placeholder="Search..."
              size="small"
              sx={{ width: "200px" }}
            />
            <Select
              value={pageInfo.isArchived}
              sx={{ height: 37, ml: 1 }}
              onChange={(e) => {
                dealPlanner.updateIsArchivedQueryParams(e.target.value);
              }}
            >
              <MenuItem value="all">All Deals</MenuItem>
              <MenuItem value="false">Active Deals</MenuItem>
              <MenuItem value="true">Archived Deals</MenuItem>
            </Select>
            <MDButton
              loading={loading}
              disabled={loading}
              size="small"
              variant="gradient"
              sx={{ ml: 2, mr: 2 }}
              color="dark"
              onClick={() => fetchDealPlanListProxy()}
            >
              Search
            </MDButton>
            <MDButton
              variant="gradient"
              sx={{ ml: "auto", mr: 2 }}
              color="info"
              onClick={() => {
                // empty current plan data
                resetDealPlanState();
                navigate("/create-a-deal");
              }}
            >
              Add Plan
            </MDButton>
          </MDBox>
          <Table
            columns={columns}
            data={tableData}
            pagination={pageInfo}
            onTableChange={console.log}
          />
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default DataTables;

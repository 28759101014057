/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import Stack from "@mui/material/Stack";
import MDButton from "components/MDButton";
import { useFlatInject } from "store";
import React, { useEffect, useState } from "react";

function Payment() {
  // get current user Payment status, expired date, etc
  const [user] = useFlatInject("user");
  const {
    user: userObject,
    upgradePayment,
    cancelPayment,
    resumeSubscription,
    // fetchPaymentInfo,
  } = user;
  const { endAt, canceledAt, vip, paymentStatus } = userObject;

  const dateParsed = Number(endAt) * 1000;

  const expiredAt = new Date(dateParsed).toDateString();
  const vipStatues = vip === 1 ? "Deal-Maker" : "Basic";

  const [open, setOpen] = useState({
    cancel: false,
    resume: false,
    upgrade: false,
  });

  return (
    <Card id="sessions">
      <MDBox p={3} lineHeight={1}>
        <MDBox mb={1}>
          <MDTypography variant="h5">Subscription and Payment</MDTypography>
        </MDBox>
      </MDBox>
      <MDBox pb={3} px={3} sx={{ overflow: "auto" }}>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width={{ xs: "max-content", sm: "100%" }}
        >
          <MDBox display="flex" alignItems="center">
            <MDBox textAlign="center" color="text" px={{ xs: 0, md: 1.5 }} opacity={0.6}>
              <Icon fontSize="default">desktop_windows</Icon>
            </MDBox>
            <MDBox height="100%" ml={2} lineHeight={1} mr={2}>
              <MDTypography display="block" variant="button" fontWeight="regular" color="text">
                Your current subscription status
              </MDTypography>
              <MDTypography variant="caption" color="success">
                {expiredAt}
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox display="flex" alignItems="center" gap={2}>
            {canceledAt === 0 ? (
              <>
                <MDBadge
                  variant="contained"
                  size="xs"
                  badgeContent="active"
                  color="success"
                  container
                />
                <MDBadge
                  variant="contained"
                  size="xs"
                  badgeContent="auto renew"
                  color="success"
                  container
                />
              </>
            ) : (
              <>
                <MDBadge
                  variant="contained"
                  size="xs"
                  badgeContent="canceled"
                  color="error"
                  container
                />
                <MDBadge
                  variant="contained"
                  size="xs"
                  badgeContent="renew stoped"
                  color="error"
                  container
                />
              </>
            )}
          </MDBox>
        </MDBox>
        <Divider />
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width={{ xs: "max-content", sm: "100%" }}
        >
          <MDBox display="flex" alignItems="center">
            <MDBox textAlign="center" color="text" px={{ xs: 0, md: 1.5 }} opacity={0.6}>
              <Icon fontSize="default">desktop_windows</Icon>
            </MDBox>
            <MDBox height="100%" ml={2} lineHeight={1} mr={2}>
              <MDTypography display="block" variant="button" fontWeight="regular" color="text">
                You are currently on
              </MDTypography>
              <MDTypography
                variant="caption"
                color="text"
                sx={{
                  color: vip === 1 ? "success.main" : "error.main",
                }}
              >
                {vipStatues} Subscription
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox display="flex" alignItems="center" flexdirection="row" gap={2}>
            <Stack direction="column" spacing={2}>
              {!vip && !open.cancel && !open.resume && (
                <MDButton
                  variant="gradient"
                  disable={vip}
                  onClick={() => {
                    setOpen({
                      ...open,
                      upgrade: true,
                    });
                  }}
                >
                  Upgrade Subscription
                </MDButton>
              )}
              {open.upgrade === false ? null : (
                <MDBox
                  display="flex"
                  alignItems="center"
                  flexdirection="row"
                  sx={{
                    alignContent: "stretch",
                    justifyContent: "space-between",
                  }}
                  gap={2}
                >
                  <MDButton
                    variant="gradient"
                    color="info"
                    disable={vip}
                    onClick={() => {
                      upgradePayment().then(() => {
                        // close the dialog
                        setOpen({
                          ...open,
                          upgrade: false,
                        });
                      });
                    }}
                  >
                    Confirm
                  </MDButton>
                  <MDButton
                    variant="outlined"
                    color="error"
                    disable={vip}
                    onClick={() => {
                      setOpen({
                        ...open,
                        upgrade: false,
                      });
                    }}
                  >
                    Cancel
                  </MDButton>
                </MDBox>
              )}
              {open.upgrade && (
                <MDTypography
                  variant="caption"
                  color="success"
                  sx={{
                    overflowWrap: "break-word",
                    width: "200px",
                  }}
                >
                  Upgrade to Premium to access Deal Terms at $199 p.m. pro rata
                </MDTypography>
              )}
            </Stack>
            {canceledAt === 0 ? (
              !open.upgrade && (
                <Stack direction="column" spacing={2}>
                  <MDButton
                    variant="gradient"
                    onClick={() => {
                      setOpen({
                        ...open,
                        cancel: true,
                      });
                    }}
                  >
                    Cancel Subscription
                  </MDButton>
                  {open.cancel === true && (
                    <MDBox
                      display="flex"
                      alignItems="center"
                      flexdirection="row"
                      gap={2}
                      sx={{
                        alignContent: "stretch",
                        justifyContent: "space-between",
                      }}
                    >
                      <MDButton
                        variant="outlined"
                        color="error"
                        disable={vip}
                        onClick={() => {
                          cancelPayment().then(() => {
                            // close the dialog
                            setOpen({
                              ...open,
                              cancel: false,
                            });
                          });
                        }}
                      >
                        Confirm
                      </MDButton>
                      <MDButton
                        variant="gradient"
                        color="info"
                        disable={vip}
                        onClick={() => {
                          setOpen({
                            ...open,
                            cancel: false,
                          });
                        }}
                      >
                        Cancel
                      </MDButton>
                    </MDBox>
                  )}
                  {open.cancel && (
                    <MDTypography
                      display="block"
                      fontWeight="regular"
                      color="error"
                      sx={{
                        fontSize: "0.8rem",
                        overflowWrap: "break-word",
                        width: "200px",
                      }}
                    >
                      Cancel subscription and delete data.
                    </MDTypography>
                  )}
                </Stack>
              )
            ) : open.resume === false ? (
              !open.upgrade && (
                <MDButton
                  variant="gradient"
                  disable={vip}
                  onClick={() => {
                    setOpen({
                      ...open,
                      resume: true,
                    });
                  }}
                >
                  Resume Subscription
                </MDButton>
              )
            ) : (
              <MDBox
                display="flex"
                alignItems="center"
                flexdirection="row"
                gap={2}
                sx={{
                  alignContent: "stretch",
                  justifyContent: "space-between",
                }}
              >
                <MDButton
                  variant="gradient"
                  color="info"
                  disable={vip}
                  onClick={() => {
                    resumeSubscription().then(() => {
                      // close the dialog
                      setOpen({
                        ...open,
                        resume: false,
                      });
                    });
                  }}
                >
                  Confirm
                </MDButton>
                <MDButton
                  variant="outlined"
                  color="error"
                  disable={vip}
                  onClick={() => {
                    setOpen({
                      ...open,
                      resume: false,
                    });
                  }}
                >
                  Cancel
                </MDButton>
              </MDBox>
            )}
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Payment;

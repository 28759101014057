/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

// Data
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useFlatInject } from "store";
import React from "react";
import { useForm, Form, FormItem } from "../../../../../components/Form";
import { ZebraPrintBoxWarp } from "../DealSummery";

function SecondDealPitch() {
  const [
    {
      dealReview,
      dealAnalysis,
      dealBundleWorkSheet,
      preClosingStrategyTable,
      gapFundingStrategyTable,
      postClosingStrategyTable,
      calculateTotal,
      termSheet,
      dealDue,
      nextStep,
    },
  ] = useFlatInject("dealPlanOperation");
  return (
    <Card id="second-deal-pitch" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h4">{dealReview.businessName} Deal Repitch</MDTypography>
      </MDBox>
      <MDBox pb={3} px={3}>
        <Grid container spacing={3}>
          {/* <Grid item xs={12}>
            <MDTypography></MDTypography>
          </Grid> */}
          <MDBox px={3} pt={3} width="100%" textAlign="center">
            <MDTypography variant="h6">Deal Overview</MDTypography>
          </MDBox>
          <MDBox px={3} pt={3}>
            <MDTypography variant="h6">Pre Meet</MDTypography>
          </MDBox>
          <Grid item xs={12}>
            <ZebraPrintBoxWarp>
              <MDTypography variant="body2">
                Product / Service Overview: {dealReview.productAndServiceOverview}
              </MDTypography>
              <MDTypography variant="body2">Industry Info: {dealReview.industryInfo}</MDTypography>
              <MDTypography variant="body2">Timing: {dealReview.timing}</MDTypography>
              <MDTypography variant="body2">
                Industry Multiple: {dealReview.industryMultiplie}
              </MDTypography>
              <MDTypography variant="body2">
                Connection In Industry: {dealReview.connectionInIndustry}
              </MDTypography>
              <MDTypography variant="body2">
                Impact to Empire: {dealReview.impactToEmpire}
              </MDTypography>
            </ZebraPrintBoxWarp>
          </Grid>
          <MDBox px={3} pt={3}>
            <MDTypography variant="h6">First Meet Criticals</MDTypography>
          </MDBox>
          <Grid item xs={12}>
            <ZebraPrintBoxWarp>
              <MDTypography variant="body2">
                Motivation to Sell: {dealReview.impactToEmpire}
              </MDTypography>
              <MDTypography variant="body2">
                Their Personal Story: {dealReview.theirPersonalStory}
              </MDTypography>
              <MDTypography variant="body2">
                The Business Story: {dealReview.theBusinessStory}
              </MDTypography>
              <MDTypography variant="body2">Owner Is A: {dealReview.ownerIsA}</MDTypography>
              <MDTypography variant="body2">Number Of Team: {dealReview.numberOfTeam}</MDTypography>
              <MDTypography variant="body2">Going Concern: {dealReview.goingConcern}</MDTypography>
              <MDTypography variant="body2">
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                What's Challenging About The Business: {dealReview.businessChallenge}
              </MDTypography>
              <MDTypography variant="body2">
                AVG Monthly Turn Over: {dealReview.avgMonthlyTurnOver}
              </MDTypography>
              <MDTypography variant="body2">
                AVG Monthly Expenses: {dealReview.avgMonthlyExpenses}
              </MDTypography>
              <MDTypography variant="body2">
                AVG Monthly Profit: {dealReview.avgMonthlyProfit}
              </MDTypography>
              <MDTypography variant="body2">
                Time Frames Sell: {dealReview.timeFramesSell}
              </MDTypography>
              <MDTypography variant="body2">
                Any Other Leverage Points: {dealReview.anyOtherLeveragePoints}
              </MDTypography>
              <MDTypography variant="body2">DEAL NOTES: {dealReview.dealReviewNotes}</MDTypography>
            </ZebraPrintBoxWarp>
          </Grid>

          <MDBox px={3} pt={3} width="100%" textAlign="center">
            <MDTypography variant="h6">Deal Analysis</MDTypography>
          </MDBox>
          <MDBox px={3} pt={3}>
            <MDTypography variant="h6">Business Overview </MDTypography>
          </MDBox>
          <Grid item xs={12}>
            <ZebraPrintBoxWarp>
              {dealAnalysis.businessOverviewForm.map((i) => (
                <MDTypography variant="body2" key={i.label}>
                  {i.label}: $ {i.value}, {i.notes}
                </MDTypography>
              ))}
            </ZebraPrintBoxWarp>
          </Grid>
          <MDBox px={3} pt={3}>
            <MDTypography variant="h6">Deal Overview</MDTypography>
          </MDBox>
          <Grid item xs={12}>
            <ZebraPrintBoxWarp>
              {dealAnalysis.dealOverviewForm.map((i) => (
                <MDTypography variant="body2" key={i.label}>
                  {i.label}: $ {i.value}, {i.notes}
                </MDTypography>
              ))}
              <MDTypography variant="body2">DEAL NOTES: {dealAnalysis.dealNotes}</MDTypography>
            </ZebraPrintBoxWarp>
          </Grid>
          <Grid item xs={12} />

          <MDBox px={3} pt={3} width="100%" textAlign="center">
            <MDTypography variant="h6">Deal Bundle WorkSheet</MDTypography>
          </MDBox>
          <Grid item xs={12}>
            <ZebraPrintBoxWarp>
              <MDBox display="flex">
                <MDTypography variant="body2" flex={1}>
                  Ask Price: ${dealBundleWorkSheet.askPrice}
                </MDTypography>
                <MDTypography flex={1} variant="body2">
                  Ebitda: {dealBundleWorkSheet.ebitda}
                </MDTypography>
                <MDTypography flex={1} variant="body2">
                  Ask Multiple: {dealBundleWorkSheet.askMultiple}
                </MDTypography>
              </MDBox>
              <MDBox display="flex">
                <MDTypography flex={2} variant="body2">
                  Goal Purchase Price: {dealBundleWorkSheet.preClosingStrategy.goalPurchasePrice}
                </MDTypography>
                <MDTypography flex={1} variant="body2">
                  Goal Multiple: {dealBundleWorkSheet.preClosingStrategy.goalMultiple}
                </MDTypography>
              </MDBox>
            </ZebraPrintBoxWarp>
          </Grid>

          <MDBox px={3} pt={3}>
            <MDTypography variant="h6">Pre-Closing Strategy</MDTypography>
          </MDBox>
          <Grid item xs={12}>
            <ZebraPrintBoxWarp>
              {preClosingStrategyTable.map((i) => (
                <MDTypography variant="body2" key={i.label}>
                  {i.label}: $ {i.value}, {i.notes}
                </MDTypography>
              ))}
              <MDTypography variant="body2">
                Total Cash Impact : $ {calculateTotal(preClosingStrategyTable, ["Y", "N"])}
              </MDTypography>
            </ZebraPrintBoxWarp>
          </Grid>
          <MDBox px={3} pt={3}>
            <MDTypography variant="h6">Gap Funding Strategy</MDTypography>
          </MDBox>
          <Grid item xs={12}>
            <ZebraPrintBoxWarp>
              <MDBox display="flex">
                <MDTypography flex={1} variant="body2">
                  Goal Purchase Price: ${dealBundleWorkSheet.gapFundingStrategy.goalPurchasePrice}
                </MDTypography>
                <MDTypography flex={1} variant="body2">
                  Deal Bundle Value: ${dealBundleWorkSheet.gapFundingStrategy.dealBundleValue}
                </MDTypography>
                <MDTypography flex={1} variant="body2">
                  Gap Left: ${dealBundleWorkSheet.gapFundingStrategy.gapLeft}
                </MDTypography>
              </MDBox>
            </ZebraPrintBoxWarp>
          </Grid>
          <Grid item xs={12}>
            <ZebraPrintBoxWarp>
              {gapFundingStrategyTable.map((i) => (
                <MDTypography variant="body2" key={i.label}>
                  {i.label}: $ {i.value}, {i.notes}
                </MDTypography>
              ))}
              <MDTypography variant="body2">
                Total Gap Funded : ${" "}
                {dealBundleWorkSheet.askPrice -
                  calculateTotal(preClosingStrategyTable) -
                  calculateTotal(gapFundingStrategyTable)}
              </MDTypography>
            </ZebraPrintBoxWarp>
          </Grid>
          <MDBox px={3} pt={3}>
            <MDTypography variant="h6">Post-Closing Strategy</MDTypography>
          </MDBox>
          <Grid item xs={12}>
            <ZebraPrintBoxWarp>
              {postClosingStrategyTable.map((i) => (
                <MDTypography variant="body2" key={i.label}>
                  {i.label}: $ {i.value}, {i.notes}
                </MDTypography>
              ))}
              <MDTypography variant="body2">
                Total Cash Impact : $ {calculateTotal(postClosingStrategyTable, ["Y", "N"])}
              </MDTypography>

              {/* <MDBox px={3} pt={3}>
            <MDTypography variant="h6">Deal Notes</MDTypography>
          </MDBox> */}

              <MDTypography variant="body2">
                Deal Notes: {dealBundleWorkSheet.dealNotes.notes}
              </MDTypography>
              <MDBox display="flex">
                <MDTypography flex={1} variant="body2">
                  Business Impact: {dealBundleWorkSheet.dealNotes.bizImpact}
                </MDTypography>
                <MDTypography flex={1} variant="body2">
                  Net Value Added: ${dealBundleWorkSheet.dealNotes.netValueAdded}
                </MDTypography>
              </MDBox>
              <MDBox display="flex">
                <MDTypography flex={1} variant="body2">
                  Annual Positive Cash Added: $
                  {dealBundleWorkSheet.dealNotes.annualPositiveCashAdded}
                </MDTypography>
                <MDTypography flex={1} variant="body2">
                  Fully Funded or Partially Funded: $
                  {dealBundleWorkSheet.dealNotes.fullyFundedOrPartiallyFunded}
                </MDTypography>
              </MDBox>
            </ZebraPrintBoxWarp>
          </Grid>

          {/* <MDBox px={3} pt={3} width="100%" textAlign="center">
            <MDTypography variant="h6">Term Sheet</MDTypography>
          </MDBox>
          <Grid item xs={12}>
            <MDTypography variant="body2">{termSheet || "No Data"}</MDTypography>
          </Grid> */}

          {/* <MDBox px={3} pt={3} width="100%" textAlign="center">
            <MDTypography variant="h6">Deal Discovery</MDTypography>
          </MDBox>
          <Grid item xs={12}>
            {dealDue.questions.map((q) => {
              const yesValue = q.children.filter((i) => i.name && i.value === "Y");
              return (
                <MDBox key={q.title}>
                  {yesValue.length ? (
                    <MDBox pt={3}>
                      <MDTypography variant="h6">{q.title}(Choose YES)</MDTypography>
                    </MDBox>
                  ) : null}
                  {yesValue.map((i, idx) => (
                    <MDTypography flex={1} key={i.name} variant="body2">
                      <b>{idx + 1}:</b>&nbsp;&nbsp;{i.name} <b>YES</b>
                    </MDTypography>
                  ))}
                </MDBox>
              );
            })}
            <MDTypography variant="body2">Deal Notes: {dealDue.notes}</MDTypography>
          </Grid> */}
        </Grid>
      </MDBox>
    </Card>
  );
}

export default SecondDealPitch;

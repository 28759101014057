/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Otis Admin PRO React components
import MDBox from "components/MDBox";

// Settings page components
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import Sidenav from "layouts/pages/account/settings/components/Sidenav";
import Header from "layouts/pages/account/settings/components/Header";
import BasicInfo from "layouts/pages/account/settings/components/BasicInfo";
import ChangePassword from "layouts/pages/account/settings/components/ChangePassword";
import Authentication from "layouts/pages/account/settings/components/Authentication";
import Accounts from "layouts/pages/account/settings/components/Accounts";
import Notifications from "layouts/pages/account/settings/components/Notifications";
import Payment from "layouts/pages/account/settings/components/Sessions";
import DeleteAccount from "layouts/pages/account/settings/components/DeleteAccount";
import MDButton from "components/MDButton";
import { useFlatInject } from "store";
import { Modal } from "utils/modal";
import { useNavigate } from "react-router-dom";
import InvoiceList from "layouts/pages/account/settings/components/Invoices";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";

function Settings() {
  const [user] = useFlatInject("user");
  const navigate = useNavigate();

  async function logout() {
    await Modal.confirm({
      title: "Warning",
      content: "Are your sure to logout?",
    });
    await user.logout();
    navigate("/sign-in");
  }
  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />
      <MDBox mt={10}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <Sidenav />
          </Grid>
          <Grid item xs={12} lg={9}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                {/* <Grid item xs={12}>
                  <Header />
                </Grid> */}
                <Grid item xs={12}>
                  <BasicInfo />
                </Grid>
                <Grid item xs={12}>
                  <ChangePassword />
                </Grid>
                <Grid item xs={12}>
                  <Payment />
                </Grid>
                <Grid item xs={12}>
                  <InvoiceList />
                </Grid>
                <Grid item xs={12} textAlign="right">
                  <MDButton color="error" onClick={() => logout()}>
                    Logout
                  </MDButton>
                </Grid>
                {/* <Grid item xs={12}> */}
                {/*  <Authentication /> */}
                {/* </Grid> */}
                {/* <Grid item xs={12}> */}
                {/*  <Accounts /> */}
                {/* </Grid> */}
                {/* <Grid item xs={12}> */}
                {/*  <Notifications /> */}
                {/* </Grid> */}
                {/* <Grid item xs={12}> */}
                {/*  <Sessions /> */}
                {/* </Grid> */}
                {/* <Grid item xs={12}> */}
                {/*  <DeleteAccount /> */}
                {/* </Grid> */}
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Settings;

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React from "react";
import { Link } from "react-router-dom";

function DescriptionSigninText() {
  return (
    <MDBox display="flex" alignItems="center" gap="6px">
      <MDTypography
        sx={{
          color: "#757575",
          fontWeight: "regular",
          fontSize: "14px",
          margin: "auto 0",
        }}
      >
        Don’t have an account?
      </MDTypography>

      <Link to="/sign-up">
        <MDTypography
          component="span"
          fontWeight="medium"
          sx={{
            textDecoration: "underline !important",
            fontSize: "14px",
            color: "#0E361C",
          }}
        >
          Sign up
        </MDTypography>
      </Link>
    </MDBox>
  );
}

export default DescriptionSigninText;

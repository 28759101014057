/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Data
import MDButton from "components/MDButton";
import { useFlatInject } from "store";
import { Message } from "utils/message";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { useHttp } from "utils/hooks";
import { Margin, usePDF } from "react-to-pdf";
import dayjs from "dayjs";
import { styled, Stack, Box, Container } from "@mui/material";
import { useForm, Form, FormItem } from "../../../../../components/Form";
import FinalTermSheetContent from "./TermSheetContent";
import UpgradeFromTermSheet from "../TermSheetupgrade/TermSheetContent";

const StyledTermSheetContent = styled(FinalTermSheetContent)`
  display: none;
  @media print {
    display: ${({ canPrint = false }) => (canPrint ? "block" : "none")} !important;
    padding: 0;
  }
`;

const initValue = () => ({
  date: new Date(),
  between:
    "<Buyer Company Full Name>, (<Buyer Company Shorthand Name>) a corporation organized and existing under the law of <Buyer Company Geo location>, with its head office address located at: <Buyer Company Address>",
  sellerCompanyName: "",
  sellerCompanyNameShort: "",
  theirAddress: "",
  yourCompanyName: "",
  yourCompanyNameShort: "",
  yourCompanyLocation: "",
  yourCompanyAddress: "",
  overallTerms: "",
  shares: "shares",
  otherShares: "",
});

/**
 * Final term sheet or term sheet .v2
 */
function FinalTermSheet() {
  // basic info preparation
  const [{ finalTermSheet, saveFinalTermSheet, switchTermSheetVersion, dealReview }] =
    useFlatInject("dealPlanOperation");
  const [{ canPrint, user, logPrint }] = useFlatInject("user");
  const userVIPstate = user;
  const { toPDF, targetRef } = usePDF({
    filename: `TermSheetV2-${dayjs().format("DD/MM")}.pdf`,
    page: { margin: Margin.MEDIUM },
  });

  const [value, setValue] = useState(
    isEmpty(finalTermSheet)
      ? initValue()
      : {
          ...initValue(),
          ...finalTermSheet,
        }
  );
  const [mode, setMode] = useState("edit");

  const form = useForm({
    initialValues: {
      text: finalTermSheet,
    },
    onSubmit: async (formData) => {
      await saveFinalTermSheet(formData.text);
      Message.success("Save success!");
    },
  });
  const location = useLocation();
  const navigate = useNavigate();

  const submitForm = async () => {
    try {
      await saveFinalTermSheet(value);
      window.location.href = `${location.pathname + location.search}#file-upload`;
      Message.success("Save success!");
    } catch (error) {
      Message.error(error.message);
    }
  };

  const { run, loading } = useHttp(submitForm, { manual: true });

  const onPrintBtnClick = () => {
    if (!canPrint) {
      Message.warning(
        "Your printing quota has been exhausted. You can utilize this feature by upgrading to a premium package."
      );
      return;
    }
    window.print();
  };

  const onSavePdf = () => {
    setMode("view");
    switchTermSheetVersion("v2");
    setTimeout(() => {
      toPDF();
      setMode("edit");
    }, 1000);
  };

  useEffect(() => {
    const afterPrintHandler = (e) => {
      if (canPrint) {
        logPrint();
      }
    };
    window.addEventListener("afterprint", afterPrintHandler);
    return () => window.removeEventListener("afterprint", afterPrintHandler);
  }, [canPrint]);

  // IMPORTANT: className === "no-print" is used to hide the component when printing
  return (
    <Card
      id="final-term-sheet"
      sx={{
        overflow: "visible",
        "@media print": {
          boxShadow: "none",
        },
      }}
    >
      <MDBox p={3}>
        {user.vip === 0 ? (
          <>
            <Box display="flex" alignItems="center" mt="50px">
              <MDTypography variant="h4">Term Sheet .v2 </MDTypography>
            </Box>
            <MDBox p={3}>
              <Stack direction="row" spacing={2} alignItems="center">
                <MDTypography variant="h6" color="secondary">
                  Please upgrade to Premium user to utilize the function of Deal dashboard Term
                  sheet
                </MDTypography>
                <UpgradeFromTermSheet />
              </Stack>
            </MDBox>
          </>
        ) : (
          <div>
            <MDBox ref={targetRef}>
              <Box display="flex" alignItems="center" mt="50px">
                <MDTypography variant="h4">Term Sheet .v2 </MDTypography>
              </Box>
              <FinalTermSheetContent
                className="no-print"
                mode={mode}
                value={value}
                onChange={setValue}
              />
            </MDBox>
            <StyledTermSheetContent canPrint={canPrint} value={value} />
            <MDBox pb={3} py={3} className="no-print">
              <Grid item xs={12} textAlign="right">
                <MDButton
                  color="green"
                  variant="outlined"
                  onClick={() => navigate("/deal-planner", { replace: true })}
                  sx={{ mr: 1 }}
                >
                  Cancel
                </MDButton>
                <MDButton
                  color="green"
                  sx={{ mr: 1 }}
                  onClick={() => {
                    switchTermSheetVersion("v2");
                    setTimeout(() => {
                      onPrintBtnClick();
                    }, 4000);
                  }}
                >
                  Print
                </MDButton>
                <MDButton color="green" sx={{ mr: 1 }} onClick={onSavePdf}>
                  Save PDF
                </MDButton>
                <MDButton
                  color="green"
                  disabled={loading || !dealReview.businessName}
                  loading={loading}
                  onClick={() => run()}
                >
                  Save and next
                </MDButton>
              </Grid>
            </MDBox>
          </div>
        )}
      </MDBox>
    </Card>
  );
}

export default FinalTermSheet;

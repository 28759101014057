const preCloseStrategiesValue = [
  "VENDOR FINANCE STRATEGY",
  "CARVE-OUT DEAL STRATEGY",
  "EARN OUT - PERFORMANCE BASED",
  "EARN OUT - TIME BASED",
  "EARN OUT - TASK BASED",
  "EARN OUT - COMBINATION",
  "DEBT ASSUMPTION STRATEGY",
  "THE MONEY BAGS STRATEGY",
  "CO-INVESTORS STRATEGY",
  "THE COMFY HEADLOCK STRATEGY",
  "THE 2 STEP STRATEGY",
  "THE 3 STEP STRATEGY",
  "RENT-A-BUSINESS STRATEGY",
  "LEASE-A-BUSINESS STRATEGY",
  "HARD LOANS STRATEGY",
  "CALL OPTION DEAL STRATEGY",
  "PUT OPTION DEAL STRATEGY",
  "OPTION STRATEGY",
  "DIFFERED DEPOSIT STRATEGY",
  "THE FIRE SALE STRATEGY",
  "ASSET TO EQUITY TRADE STRATEGY",
  "LANDLORD DEFERRAL",
  "LANDLORD BUY IN STRATEGY",
  "CONSIGNMENT ASSETS TRADE STRATEGY",
  "CONSULTING SELLER - TIME BASED STRATEGY",
  "CONSULTING SELLER - PERFORMANCE BASED STRATEGY",
  "CONSULTING SELLER - PERFORMANCE & TIME BASED STRATEGY",
  "A/R DEFERRAL STRATEGY",
  "FACILITATION STRATEGY",
  "SPONSORSHIP DEAL",
  "INVENTORY PAYBACK",
  "GIFT PURCHASE STRATEGY",
  "BALLOON STRATEGY",
  "POWER BROKER STRATEGY",
  "MONOPOLY EMPIRE STRATEGY",
  "STOCK SWAP STRATEGY",
  "STOCK & CASH STRATEGY",
  "LABOUR ADD BACK STRATEGY",
  "LBO STRATEGY",
  "INSTANT EARN IN STRATEGY",
  "TIMED EARN IN STRATEGY",
  "TASK EARN IN STRATEGY",
  "REVENUE EARN IN STRATEGY",
  "PROFIT EARN IN STRATEGY",
  "SWEAT EQUITY EARN IN STRATEGY",
];

const preCloseStrategiesDesc = [
  "GETTING THE VENDOR TO PART OR FULL FINANCE THE DEAL",
  "DEAL WHERE YOU FIND PARTS OF THE BUSINESS YOU DON’T NEED AND REMOVE FROM THE DEAL",
  "PAY SELLER PURCHASE PRICE AS AND WHEN BUSINESS HITS PRE AGREED PERFORMANCE",
  "PAY SELLER PURCHASE PRICE OVER PERIOD OF TIME",
  "PAY SELLER AS AND WHEN TASK(S) AGREED ARE COMPLETED",
  "PAY SELLER WITH COMBINATION OF TIME, PERFORMANCE AND OR TASK MILESTONES",
  "TAKE OVER PART OR ALL OF SELLERS DEBTS EITHER PERSONAL OR BUSINESS",
  "BRING ANOTHER PARTY INTO THE DEAL WITH THE MONEY, THEY GET EQUITY, AND YOU GET EQUITY FOR FINDING THE INVESTOR",
  "PRIVATE INVESTORS",
  "THEY GIVE YOU EQUITY IN COMPANY TO KEEP THE DEAL AND KEEP YOU SENDING THEM BUSINESS",
  "YOUR SALES CAMPAIGN PRIOR TO DEAL CLOSE",
  "SOMEONE ELSES SALES CAMPAIGN PRIOR TO DEAL CLOSE",
  "PAY RENT MONTHLY, QUARTERLY OR ANNUALLY TO TAKE OVER THE BUSINESS",
  "PAY A LEASE MONTHLY, QUARTERLY OR ANNUALLY TO TAKE OVER THE BUSINESS",
  "GET LOAN AGAINST HARD ASSETS IN THE COMPANY YOU’RE BUYING WITH HARD MONEY LENDER",
  "NOT LOCKED IN TO DOING THE DEAL.",
  "LOCKED IN TO DOING THE DEAL.",
  "AGREE A DEAL AND RE SELL THE BUSINESS BEFORE CLOSING HIGHER AND MAKE THE SPREAD",
  "PAYING THE SELLER DEPOSIT DIFFERED BUT TAKE OVER BUSINESS IMMEDIATLEY",
  "DIFFERED PAYMENT INTO A FIRE SELL OFF",
  "TRADE ASSET YOU OWN TO AQUIRE THEIR EQUITY",
  "LANDLORD AGREE TO DEFER RENT FOR X PERIOD",
  "APPROACH LANDLORD TO BY IN VIA CASH OR RENTAL VALUE",
  "PAID FOR ASSETS POST CLOSING ON AGREED TIME FRAME",
  "PAY SELLER CONSULTING FEE OVER PERIOD OF TIME",
  "PAY SELLER CONSULTING FEE BASED ON PERFORMANCE TARGETS",
  "PAY SELLER CONSULTING FEE OVER TIME & BASED ON PERFORMANCE TARGETS",
  "TAKE THE A/R FROM PRICE AND AGREE TO PAY WHEN ITS RECEIVED",
  "GET PAID OR GET EQUITY FOR FACILITATING THE DEAL",
  "GET A SPONSOR TO PAY YOU FOR EXCLUSIVE ACCESS TO NEW BUSINESS CUSTOMERS",
  "PAY BACK SELLER THE INVENTORY AS YOU SELL IT",
  "USING A CREDIT CARD TO BUY SOMETHING THEY WANT AND TAKE THE VALUE OUT OF THE DEAL",
  "PAY SMALLER AMOUNT UPFRONT AND LARGER AMOUNT AT AGREED LATER DATE",
  "DO SOMETHING THE SELLER NEEDS THATS VALUABLE TO THEM AND AGREE A VALUE TO COME OUT",
  "GIVE THE SELLER EQUITY IN ONE OR MORE OF YOUR OTHER VENTURES AND TAKE OF PRICE",
  "MERGE COMPANY INTRO YOURS AND GIVE THE SELLER STOCK IN YOUR COMPANY",
  "MERGE COMPANY INTRO YOURS AND GIVE THE SELLER CASH + STOCK IN YOUR COMPANY",
  "REMOVING A ONE YEAR COST OF REPLACING THE SELLER IN THE BUSINESS THEN RE WORKING TRUE VALUE",
  "ASSUME THE TOTAL DEBT INTO YOUR OWN DEBT FACILITIES",
  "WHEN YOU PROVIDE TIME, EFFORT, OR RESOURCES IN EXCHANGE FOR % IN COMPANY NOW",
  "WHEN YOU EARN EQUITY OVER A PERIOD OF TIME PRE AGREED",
  "WHEN YOU EARN EQUITY BASED ON COMPLETING A SPECIFIC PRE-AGREED TASK(S)",
  "WHEN YOU EARN EQUITY BASED ON REVENUE MILESTONES PRE-AGREED",
  "WHEN YOU EARN EQUITY BASED ON PROFIT MILESTONES PRE-AGREED",
  "AN OBJECTIVE BASED AGREEMENT TO GET EQUITY ",
];

export const preCloseStrategies = preCloseStrategiesValue.map((i, idx) => ({
  value: i,
  description: preCloseStrategiesDesc[idx],
  id: i,
  label: i,
}));

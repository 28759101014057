/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @material-ui core components
import {
  MenuItem,
  Select,
  Grid,
  Card,
  FormControl,
  InputLabel,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

import { css } from "@emotion/css";
// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

// Data
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDRadio from "components/MDRadio";
import MDDatePicker from "components/MDDatePicker";
import * as Yup from "yup";
import { useFlatInject } from "store";
import { useHttp } from "utils/hooks";
import { Message } from "utils/message";
import { useEffect, useId } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MoneyInput } from "components/MoneyInput";
import pxToRem from "assets/theme/functions/pxToRem";
import { useForm, Form, FormItem } from "../../../../../components/Form";

export function SelectWithLabel({ ...props }) {
  const id = useId();
  return (
    <FormControl fullWidth>
      <InputLabel id={id}>{props.label}</InputLabel>
      <Select labelId={id} label="Age" {...props} />
    </FormControl>
  );
}

function GoingConcernTrigger({ ...props }) {
  const id = useId();
  return (
    <MDBox mt={0.5}>
      <FormControl>
        <FormLabel id={id} style={{ fontSize: 12 }}>
          Going Concern
        </FormLabel>
        <MDBox py={0.8} />
        <RadioGroup row {...props}>
          <FormControlLabel
            value="Yes"
            control={<MDRadio />}
            label={<MDTypography variant="subtitle2">Yes</MDTypography>}
          />
          <FormControlLabel
            value="No"
            control={<MDRadio />}
            label={<MDTypography variant="subtitle2">No</MDTypography>}
          />
        </RadioGroup>
      </FormControl>
    </MDBox>
  );
}

function DealOverview() {
  const [{ dealReview, saveDealReview, nextStep }] = useFlatInject("dealPlanOperation");
  const location = useLocation();
  const form = useForm({
    initialValues: dealReview,
    validationSchema: Yup.object().shape({
      businessName: Yup.string().required("Business name is required"),
    }),
    onSubmit: async (formData) => {
      try {
        await saveDealReview(formData);
        window.location.href = `${location.pathname + location.search}#deal-analysis`;
        Message.success("save success!");
      } catch (error) {
        Message.error(error.message);
      }
    },
  });
  const navigate = useNavigate();

  useEffect(() => {
    // console.log(form);
    form.setValues(dealReview);
  }, [dealReview]);

  return (
    <Card id="deal-review" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h4">Deal Overview</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Form form={form} fieldStyle={{ flex: 1 }} labelSx={{ display: "none" }}>
          <MDBox pb={4}>
            <MDTypography variant="body2">* Required information</MDTypography>
          </MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormItem
                as={MDInput}
                size="normal"
                type="text"
                name="businessName"
                variant="standard"
                fullWidth
                label="Business name"
                placeholder="enter business name"
                required
              />
            </Grid>
            <MDBox px={3} py={2}>
              <MDTypography variant="h6">Pre meet</MDTypography>
            </MDBox>
            <Grid container px={3} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2 }}>
              <Grid item xs={6}>
                <FormItem
                  as={MDInput}
                  type="text"
                  name="productAndServiceOverview"
                  variant="standard"
                  fullWidth
                  label="Product / service overview	"
                  placeholder="enter here"
                />
              </Grid>
              <Grid item xs={6}>
                <FormItem
                  as={MDInput}
                  type="text"
                  name="industryInfo"
                  variant="standard"
                  fullWidth
                  label="Industry info"
                  placeholder="enter here"
                />
              </Grid>
              <Grid item xs={6}>
                <FormItem
                  as={SelectWithLabel}
                  type="date"
                  name="timing"
                  variant="standard"
                  fullWidth
                  label="Timing"
                  InputLabelProps={{ shrink: true }}
                  placeholder="enter here"
                >
                  <MenuItem value="Not Good">Not Good</MenuItem>
                  <MenuItem value="Good">Good</MenuItem>
                  <MenuItem value="Great">Great</MenuItem>
                </FormItem>
              </Grid>
              <Grid item xs={6}>
                <FormItem
                  as={MDInput}
                  type="text"
                  name="industryMultiplie"
                  variant="standard"
                  fullWidth
                  label="Industry multiple"
                  placeholder="enter here"
                />
              </Grid>
              <Grid item xs={6}>
                <FormItem
                  as={MDInput}
                  type="text"
                  name="connectionInIndustry"
                  variant="standard"
                  fullWidth
                  label="Connection in industry"
                  placeholder="enter here"
                />
              </Grid>
              <Grid item xs={6}>
                <FormItem
                  as={MDInput}
                  type="text"
                  name="impactToEmpire"
                  variant="standard"
                  fullWidth
                  label="Impact to empire	"
                  placeholder="enter here"
                />
              </Grid>
            </Grid>
            <MDBox px={3} pt={3}>
              <MDTypography variant="h6">First meet criticals</MDTypography>
            </MDBox>
            <Grid item xs={12}>
              <FormItem
                as={MDInput}
                type="text"
                name="motivationToSell"
                variant="standard"
                fullWidth
                label="Motivation to sell"
                placeholder="enter here"
              />
            </Grid>
            <Grid item xs={12}>
              <FormItem
                as={MDInput}
                type="text"
                name="theirPersonalStory"
                variant="standard"
                fullWidth
                label="Their personal story"
                placeholder="enter here"
              />
            </Grid>
            <Grid item xs={12}>
              <FormItem
                as={MDInput}
                type="text"
                name="theBusinessStory"
                variant="standard"
                fullWidth
                label="The business story	"
                placeholder="enter here"
              />
            </Grid>
            <Grid item xs={4}>
              <FormItem
                as={MDInput}
                type="number"
                name="yearsInBusiness"
                variant="standard"
                fullWidth
                label="Years in business"
                placeholder="0"
              />
            </Grid>
            <Grid item xs={4}>
              <FormItem
                as={SelectWithLabel}
                type="text"
                name="ownerIsA"
                variant="standard"
                fullWidth
                label="Owner is a"
                placeholder="select option"
              >
                <MenuItem value="Job">1 - Job</MenuItem>
                <MenuItem value="Business">2 - Business</MenuItem>
                <MenuItem value="Empire">3 - Empire</MenuItem>
              </FormItem>
            </Grid>
            <Grid item xs={4}>
              <FormItem
                as={MDInput}
                type="number"
                name="numberOfTeam"
                variant="standard"
                fullWidth
                label="Number of team"
                placeholder="0"
              />
            </Grid>
            <Grid item xs={12} display="flex">
              <FormItem
                as={GoingConcernTrigger}
                type="text"
                name="goingConcernTrigger"
                variant="standard"
                fullWidth
                label="Going Concern Trigger"
              />
              {form.values.goingConcernTrigger === "Yes" ? (
                <FormItem
                  as={MDInput}
                  type="text"
                  name="goingConcern"
                  variant="standard"
                  fullWidth
                  label="Why?"
                  placeholder="enter here"
                  formItemSx={{ flex: 1, marginTop: 2 }}
                />
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <FormItem
                as={MDInput}
                type="text"
                name="businessChallenge"
                variant="standard"
                fullWidth
                label="What's challenging about the business"
                placeholder="enter here"
              />
            </Grid>
            <Grid item xs={3}>
              <FormItem
                as={MoneyInput}
                type="number"
                name="avgMonthlyTurnOver"
                variant="standard"
                fullWidth
                label="Average monthly turn over"
              />
            </Grid>

            <Grid item xs={3}>
              <FormItem
                as={MoneyInput}
                type="number"
                name="avgMonthlyExpenses"
                variant="standard"
                fullWidth
                label="Average monthly expenses"
              />
            </Grid>
            <Grid item xs={3}>
              <FormItem
                as={MoneyInput}
                type="number"
                name="AverageMonthlyProfit"
                variant="standard"
                fullWidth
                label="Average monthly profit"
              />
            </Grid>

            <Grid item xs={3}>
              <FormItem
                as={MoneyInput}
                type="text"
                name="timeFramesSell"
                variant="standard"
                fullWidth
                label="Time frames sell"
              />
            </Grid>
            <Grid item xs={12}>
              <FormItem
                as={MDInput}
                type="text"
                name="anyOtherLeveragePoints"
                variant="standard"
                fullWidth
                label="Any other leverage points?"
                placeholder="enter here"
              />
            </Grid>
            <Grid item xs={12}>
              <FormItem
                as={MDInput}
                multiline
                type="text"
                name="dealReviewNotes"
                variant="standard"
                label="Other deal notes?"
                fullWidth
                placeholder="enter here"
              />
            </Grid>

            <Grid item xs={12} textAlign="right">
              <MDButton
                color="green"
                variant="outlined"
                onClick={() => navigate("/deal-planner", { replace: true })}
                sx={{ mr: 1 }}
              >
                Cancel
              </MDButton>
              <MDButton
                color="green"
                loading={form.isSubmitting}
                disabled={form.isSubmitting}
                onClick={form.submitForm}
              >
                Save and next
              </MDButton>
            </Grid>
          </Grid>
        </Form>
      </MDBox>
    </Card>
  );
}

export default DealOverview;

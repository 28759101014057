import MDTypography from "components/MDTypography";
import React from "react";

function SigninText() {
  return (
    <MDTypography
      fontSize="32px"
      fontWeight="regular"
      sx={{
        color: "#282828",
      }}
    >
      Sign in
    </MDTypography>
  );
}

export default SigninText;

const postCloseStrategiesValues = [
  "SUPPLIER LOANS STRATEGY",
  "SUPPLIER EQUITY STRATEGY",
  "SUPPLIER CONSIGNMENT STRATEGY",
  "ACCOUNTS RECIEVABLE LOAN STRATEGY",
  "MEZZANNINE FINANCE STRATEGY",
  "PLANT AND EQUIPTMENT SELL OFF STRATEGY",
  "PLANT AND EQUIPTMENT REFINANCE STRATEGY",
  "F.F.E LIQUIDATION STRATEGY",
  "IP SELL-OFF STRATEGY",
  "MANAGER EQUITY STRATEGY",
  "INVESTOR BUY IN STRATEGY",
  "THE SEE-SAW STRATEGY",
  "INVENTORY SELL OFF STRATEGY",
  "STOCK SELL OFF STRATEGY",
  "SUB LEASE TO 3RD PARTY STRATEGY",
  "RECURRING RUN UP STRATEGY",
  "CAPITAL RAISE STRATEGY",
  "DEBT RESTRUCTURE STRATEGY",
  "GRANT WRITING STRATEGY",
];
const postCloseStrategiesDesc = [
  "SUPPLIER TO EXTEND CREDIT TERMS OVER X PERIOD OR ALLOW FREE CASH TO PAY BACK BUY IN",
  "OFFER SUPPLIER EQUITY IN BUSINESS IN EXCHANGE FOR CASH AND BETTER SUPPLY TERMS",
  "ASK SUPPLIER TO GIVE YOU CONSIGNMENT TERMS",
  "GET A LOAN OVER THE A/R THAT COMING IN",
  "HYBRID FINANCING OF DEBT AND EQUITY",
  "SELL OFF UNWANTED PLANT OR EQUIPTMENT",
  "RE FINANCE PLANT AND EQUIPTMENT LOANS TO LOWER COST",
  "SELLING OFF FURNITURE, FIXTURE AND FITTINGS",
  "SELL PARTS OF THE IP IN THE BUSINESS OFF",
  "HAVING THE MANGER IN THE BUSINESS BUY IN OR MULTIPLE TEAM MEMEBERS",
  "SELLING A SHARE OF THE BUSINESS",
  "INVESTOR BUY IN ON THE JOURNEY TO A VALUATION TIPPING POINTS TO EQUITY WITH ZERO DEBT",
  "SELL INVENTORY TO OTHER COMPANIES",
  "SELL STOCK TO OTHER COMPANIES",
  "SUBLET PART OF OFFICE ",
  "GETTING ANY RECURRING CUSTOMERS TO PAY UP FRONT AND RECIEVE DISCOUNT",
  "RAISE CAPITAL FROM INVESTORS IMMEDIATELY ON CLOSING",
  "RE STRUCTURE ANY DEBT IN COMPANY AFTER ASSUMTION TO LOWER REPAYMENTS",
  "APPLY FOR GRANTS AVALIABLE ",
];

export const postCloseStrategies = postCloseStrategiesValues.map((i, idx) => ({
  value: i,
  description: postCloseStrategiesDesc[idx],
  id: i,
  label: i,
}));

/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { useNavigate } from "react-router-dom";
import bgSigninImage from "assets/images/illustrations/background-sign-in.jpg";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useCallback, useState } from "react";
import * as Yup from "yup";
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";
import { Box } from "@mui/material";
import facebookSigninLogo from "assets/images/small-logos/facebook-sign-in.svg";
import googleSigninLogo from "assets/images/small-logos/google-sign-in.svg";
import pxToRem from "assets/theme/functions/pxToRem";
import MDInputSignIn from "components/MDInputSignIn";
import { FacebookAuthProvider, GoogleAuthProvider } from "firebase/auth";
import { signinSocial } from "utils/auth";
import { Form, FormItem, useForm } from "../../components/Form";
import { useInject } from "../../store";
import DescriptionCompSignUp from "./illustration/DescriptionCompSignUp";
import TitleCompSignUp from "./illustration/TitleCompSignUp";

function Cover() {
  const [user] = useInject("user");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [er, setEr] = useState("");
  const form = useForm({
    initialValues: {
      email: "",
      password: "",
      username: "",
    },
    onSubmit: async (formData, { validateForm }) => {
      await validateForm();
      await user.actions.signUp(formData);
      navigate("/");
    },
    validationSchema: Yup.object().shape({
      username: Yup.string().min(4, "Name min length is 4").max(20, "Name max length is 20"),
      email: Yup.string().required("Email is required").email("Please input an email"),
      password: Yup.string()
        .min(8, "Password min length is 8")
        .max(20, "Password max length is 20")
        .matches(/[^\w]/, "Password must contains one special characters")
        .required("Password is required"),
    }),
  });

  const onClickShowPassword = useCallback(() => {
    setShowPassword((pre) => !pre);
  }, []);

  return (
    <IllustrationLayout
      title={<TitleCompSignUp />}
      description={<DescriptionCompSignUp />}
      illustration={bgSigninImage}
    >
      <Form form={form} fieldStyle={{ flex: 1 }} labelSx={{ display: "none" }}>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" gap="16px">
          <MDButton
            sx={{ pt: 11 / 8, pb: 11 / 8, pl: 22 / 8, pr: 22 / 8 }}
            fullWidth
            onClick={() => signinSocial(new GoogleAuthProvider(), setEr, user, navigate)}
            variant="outlined"
            color="grey"
          >
            <MDBox component="img" src={googleSigninLogo} />
          </MDButton>
          <MDButton
            sx={{ pt: 11 / 8, pb: 11 / 8, pl: 22 / 8, pr: 22 / 8 }}
            fullWidth
            onClick={() => signinSocial(new FacebookAuthProvider(), setEr, user, navigate)}
            variant="outlined"
            color="grey"
          >
            <MDBox component="img" src={facebookSigninLogo} />
          </MDButton>
        </MDBox>

        <MDBox display="flex" gap="16px" sx={{ padding: "24px 0" }}>
          <MDBox
            component="span"
            sx={{ width: "100%", background: "#757575", height: "1px", margin: "auto 0" }}
          />
          <MDTypography fontSize="14px" sx={{ color: "#282828" }}>
            OR
          </MDTypography>
          <MDBox
            component="span"
            sx={{ width: "100%", background: "#757575", height: "1px", margin: "auto 0" }}
          />
        </MDBox>

        <MDBox component="form" role="form">
          <MDBox mb={2}>
            <FormItem
              as={MDInputSignIn}
              type="text"
              name="username"
              variant="standard"
              fullWidth
              placeholder="Full name"
              InputLabelProps={{ shrink: true }}
            />
          </MDBox>
          <MDBox mb={2}>
            <FormItem
              as={MDInputSignIn}
              type="email"
              name="email"
              variant="standard"
              fullWidth
              placeholder="Email address"
              InputLabelProps={{ shrink: true }}
            />
          </MDBox>

          <MDBox mb={2}>
            <FormItem
              as={MDInputSignIn}
              type={showPassword ? "text" : "password"}
              name="password"
              variant="standard"
              fullWidth
              showPassword="true"
              eyeDisplay={showPassword}
              onShowPassword={onClickShowPassword}
              placeholder="Password"
              InputLabelProps={{ shrink: true }}
            />
          </MDBox>

          {er && (
            <Box color="error.main" fontSize={pxToRem(14)} component="span">
              {er}
            </Box>
          )}

          <MDBox mt={4} mb={1}>
            <MDButton
              variant="contained"
              color="green"
              size="large"
              fullWidth
              loading={form.isSubmitting}
              onClick={form.submitForm}
            >
              Register
            </MDButton>
          </MDBox>
        </MDBox>
      </Form>
    </IllustrationLayout>
  );
}

export default Cover;

/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import {
  RadioGroup,
  Radio,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  IconButton,
  Stack,
} from "@mui/material";
import { forwardRef, useEffect, useMemo, useImperativeHandle, useRef, useCallback } from "react";
import { debounce } from "lodash";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import plusIcon from "assets/images/logos/plus.svg";

// Data
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDRadio from "components/MDRadio";
import Table from "components/Table";
import { FieldArray } from "formik";
import { useFlatInject } from "store";
import { Message } from "utils/message";
import { useLocation, useNavigate } from "react-router-dom";
import { Subject, BehaviorSubject } from "rxjs";
import numeral from "numeral";
import { useObserver, useObserverState } from "utils/hooks";
import { MoneyInput } from "components/MoneyInput";
import { Delete, Edit, MenuBook, Money } from "@mui/icons-material";
import StrategiesSelect from "../StrategiesSelect";
import { useForm, Form, FormItem } from "../../../../../components/Form";
import { SelectWithLabel } from "../DealOverview";

const radioUI = <MDRadio />;
const fieldStyle = { flex: 1 };
const labelSx = { display: "none" };

const goalPurchasePriceSubject = new Subject();
const totalCashImpactSubject = new BehaviorSubject([0, 0, 0]);
const dealBundleValueSubject = new BehaviorSubject([0, 0, 0]);

const useSyncGoalPrice = (form, name = "goalPurchasePrice") => {
  const flag = useRef(false);

  useEffect(() => {
    if (flag.current === false) {
      return;
    }
    goalPurchasePriceSubject.next(form.values.goalPurchasePrice);
  }, [form.values.goalPurchasePrice]);

  useEffect(() => {
    flag.current = true;
  }, []);
  useObserver(goalPurchasePriceSubject, (newPrice) => {
    if (form.values.goalPurchasePrice !== newPrice) {
      form.setFieldValue(name, newPrice);
    }
  });
};

const BaseForm = forwardRef((_, ref) => {
  const [{ dealBundleWorkSheet }] = useFlatInject("dealPlanOperation", {
    state: [
      (s) => s.dealBundleWorkSheet.askPrice,
      (s) => s.dealBundleWorkSheet.ebitda,
      (s) => s.dealBundleWorkSheet.askMultiple,
    ],
  });
  const form = useForm({
    initialValues: {
      askPrice: dealBundleWorkSheet.askPrice,
      ebitda: dealBundleWorkSheet.ebitda,
      askMultiple: dealBundleWorkSheet.askMultiple,
    },
  });
  useEffect(() => {
    form.setValues({
      askPrice: dealBundleWorkSheet.askPrice,
      ebitda: dealBundleWorkSheet.ebitda,
      askMultiple: dealBundleWorkSheet.askMultiple,
    });
  }, [dealBundleWorkSheet.askPrice, dealBundleWorkSheet.ebitda, dealBundleWorkSheet.askMultiple]);
  useImperativeHandle(ref, () => ({
    getFormData: () => form.values,
    validate: () => form.validateForm(),
  }));
  const sv = totalCashImpactSubject.value;
  if (form.values.askPrice !== sv[0]) {
    totalCashImpactSubject.next([form.values.askPrice || 0, sv[1], sv[2]]);
  }

  return (
    <Form form={form} fieldStyle={fieldStyle} labelSx={labelSx}>
      <MDBox sx={{ display: "flex" }}>
        <MDBox sx={{ flex: 1 }}>
          <FormItem
            as={MoneyInput}
            name="askPrice"
            variant="standard"
            label="Ask price"
            fullWidth
            disabled
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MDTypography variant="button" color="black">
                    $
                  </MDTypography>
                </InputAdornment>
              ),
            }}
          />
        </MDBox>
        <MDBox sx={{ flex: 1 }}>
          <FormItem
            as={MoneyInput}
            name="ebitda"
            variant="standard"
            label="EBITDA"
            fullWidth
            disabled
          />
        </MDBox>
        <MDBox sx={{ flex: 1 }}>
          <FormItem
            as={MDInput}
            name="askMultiple"
            variant="standard"
            label="Ask multiple"
            fullWidth
            disabled
          />
        </MDBox>
      </MDBox>
    </Form>
  );
});

const PreClosingStrategy = forwardRef((_, ref) => {
  const [{ dealBundleWorkSheet, calculateTotal, updateDealBundleValueOfPreClosingStrategy }] =
    useFlatInject("dealPlanOperation", {
      state: ["dealBundleWorkSheet"],
    });
  const { goalPurchasePrice, goalMultiple, table, notes } = dealBundleWorkSheet.preClosingStrategy;
  const form = useForm({
    initialValues: {
      goalPurchasePrice,
      goalMultiple,
      table,
      notes,
    },
    onSubmit: _.submit,
  });
  useImperativeHandle(ref, () => ({
    getFormData: () => form.values,
    validate: () => form.validateForm(),
  }));

  useSyncGoalPrice(form);

  const addMorePreClosing = () => {
    form.setFieldValue("table", [
      ...form.values.table,
      {
        name: "",
        yesOrNo: "Y",
        value: null,
        notes: "",
      },
    ]);
  };

  const sv = totalCashImpactSubject.value;

  const [askPrice] = useObserverState(totalCashImpactSubject, sv);

  const totalCashImpactPrice = calculateTotal(form.values.table, ["Y", "N"]);
  useEffect(() => {
    // caculate the Goal Purchase Price
    const currentGoalPurchasePrice = Number(form.values.goalPurchasePrice);
    const currentEbitda = dealBundleWorkSheet.ebitda;
    if (currentGoalPurchasePrice && currentEbitda) {
      const currentGoalMultiple = currentGoalPurchasePrice / currentEbitda;
      form.setFieldValue("goalMultiple", currentGoalMultiple);
    }

    updateDealBundleValueOfPreClosingStrategy(calculateTotal(form.values.table));
  }, [
    calculateTotal(form.values.table),
    form.values.goalPurchasePrice,
    dealBundleWorkSheet.ebitda,
  ]);
  const gapLeft = form.values.goalPurchasePrice - calculateTotal(form.values.table);
  if (totalCashImpactPrice !== sv[1]) {
    totalCashImpactSubject.next([sv[0], totalCashImpactPrice || 0, sv[2]]);
  }

  // const formSubmitRef = useRef();

  // useEffect(() => {
  //   formSubmitRef.current = debounce(() => {
  //     form.submitForm();
  //   }, 2000);
  //   return () => {
  //     formSubmitRef.current.flush();
  //   };
  // }, []);

  // // save after user stop typing for 800ms
  // useEffect(() => {
  //   formSubmitRef.current();
  // }, [form.values.goalPurchasePrice]);

  return (
    <Form form={form} fieldStyle={fieldStyle} labelSx={labelSx}>
      <MDBox py={4}>
        <MDTypography>Pre-closing strategy</MDTypography>
      </MDBox>
      <MDBox sx={{ display: "flex" }}>
        <MDBox sx={{ flex: 1 }}>
          <FormItem
            as={MoneyInput}
            name="goalPurchasePrice"
            variant="standard"
            label="Goal Purchase Price"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MDTypography variant="button" color="black">
                    $
                  </MDTypography>
                </InputAdornment>
              ),
            }}
          />
        </MDBox>
        <MDBox sx={{ flex: 1 }}>
          <FormItem
            as={MDInput}
            type="text"
            name="goalMultiple"
            variant="standard"
            label="Goal Multiple"
            fullWidth
            disabled
          />
        </MDBox>
      </MDBox>
      <MDBox py={2}>
        <FieldArray
          name="table"
          render={() => (
            <MDBox>
              {form.values.table.map((one, index) => (
                <Grid container key={`${one.name + index}`} alignItems="center" spacing={4}>
                  <Grid item xs={6}>
                    <StrategiesSelect name={`table[${index}].name`} type="preCloseStrategies" />
                  </Grid>
                  <Grid item xs={2}>
                    <MDTypography variant="caption">Used</MDTypography>
                    <FormItem as={RadioGroup} row name={`table[${index}].yesOrNo`}>
                      <Stack direction="row" p={0} m={0}>
                        <FormControlLabel value="Y" control={radioUI} label="Yes" />
                        <FormControlLabel value="N" control={radioUI} label="No" />
                      </Stack>
                    </FormItem>
                  </Grid>
                  <Grid item xs={3}>
                    <FormItem
                      variant="standard"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MDTypography variant="button" color="black">
                              $
                            </MDTypography>
                          </InputAdornment>
                        ),
                      }}
                      as={MoneyInput}
                      label="Value"
                      name={`table[${index}].value`}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      onClick={() => {
                        form.setValues({
                          table: form.values.table.filter((i) => i !== one),
                        });
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
            </MDBox>
          )}
        />
      </MDBox>
      <MDBox>
        <MDButton color="green" variant="outlined" onClick={addMorePreClosing}>
          <MDBox component="img" src={plusIcon} /> &nbsp;Add more
        </MDButton>
        <MDBox width="590px" py={4}>
          <MDInput
            label="Total Bundle Value"
            sx={{ width: 203 }}
            value={numeral(totalCashImpactPrice).format("0,0.00")}
            variant="standard"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MDTypography variant="button" color="black">
                    $
                  </MDTypography>
                </InputAdornment>
              ),
            }}
          />
        </MDBox>
        <FormItem
          as={MDInput}
          placeholder="enter here"
          multiline
          type="text"
          name="notes"
          variant="standard"
          fullWidth
          label="Notes"
        />
      </MDBox>
      {/* <MDBox sx={{ flex: 1 }}>
        <FormItem
          as={MDInput}
          multiline
          type="text"
          value={calculateTotal(form.values.table)}
          name="goalMultiple"
          variant="standard"
          label="Goal Multiple"
          fullWidth
        />
      </MDBox> */}
      <MDBox py={4}>
        <MDTypography>Gap funding strategy</MDTypography>
      </MDBox>
      <MDBox display="flex">
        <MDBox flex={1}>
          <FormItem
            as={MoneyInput}
            multiline
            name="goalPurchasePrice"
            variant="standard"
            label="Goal Purchase Price"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MDTypography variant="button" color="black">
                    $
                  </MDTypography>
                </InputAdornment>
              ),
            }}
          />
        </MDBox>
        <MDBox flex={1}>
          <MDInput
            multiline
            type="number"
            name="dealBundleValue"
            variant="standard"
            label="Deal Bundle Value"
            value={calculateTotal(form.values.table)}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MDTypography variant="button" color="black">
                    $
                  </MDTypography>
                </InputAdornment>
              ),
            }}
          />
        </MDBox>
        <MDBox flex={1}>
          <MDInput
            multiline
            type="number"
            name="gapLeft"
            variant="standard"
            label="Gap Left"
            fullWidth
            value={gapLeft}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MDTypography variant="button" color="black">
                    $
                  </MDTypography>
                </InputAdornment>
              ),
            }}
          />
        </MDBox>
      </MDBox>
    </Form>
  );
});

const GapFundingStrategy = forwardRef((_, ref) => {
  const [
    {
      dealBundleWorkSheet,
      calculateTotal,
      updateDealBundleValueOfGapFundingStrategy,
      dealBundleWorkSheetRunningCache,
    },
  ] = useFlatInject("dealPlanOperation", {
    state: [
      "dealBundleWorkSheet.gapFundingStrategy",
      "dealBundleWorkSheetRunningCache.dealBundleValue.preClosingStrategy",
    ],
  });
  const { goalPurchasePrice, dealBundleValue, gapLeft, table, notes } =
    dealBundleWorkSheet.gapFundingStrategy;
  const form = useForm({
    initialValues: {
      goalPurchasePrice,
      dealBundleValue,
      gapLeft,
      table,
      notes,
    },
  });
  useImperativeHandle(ref, () => ({
    getFormData: () => form.values,
    validate: () => form.validateForm(),
  }));

  useSyncGoalPrice(form);

  const addMoreGapFunding = () => {
    form.setFieldValue("table", [
      ...form.values.table,
      {
        name: "",
        yesOrNo: "Y",
        value: null,
        notes: "",
      },
    ]);
  };

  const sv = totalCashImpactSubject.value;

  const [, restPrice] = useObserverState(totalCashImpactSubject, sv);

  const totalCashImpactPrice = calculateTotal(form.values.table, ["Y", "N"]);

  const dealBoundleValue =
    calculateTotal(form.values.table) +
    dealBundleWorkSheetRunningCache.dealBundleValue.preClosingStrategy;

  if (totalCashImpactPrice !== sv[2]) {
    totalCashImpactSubject.next([sv[0], sv[1], totalCashImpactPrice || 0]);
  }

  useEffect(() => {
    updateDealBundleValueOfGapFundingStrategy(dealBoundleValue);
  }, [dealBoundleValue]);

  return (
    <Form form={form} fieldStyle={fieldStyle} labelSx={labelSx}>
      <MDBox py={2}>
        <FieldArray
          name="table"
          render={() => (
            <MDBox>
              {form.values.table.map((one, index) => (
                <Grid container key={`${one.name + index}`} alignItems="center" spacing={4}>
                  <Grid item xs={6}>
                    <StrategiesSelect name={`table[${index}].name`} type="gapFundingStrategies" />
                  </Grid>
                  <Grid item xs={2}>
                    <MDTypography variant="caption">Used</MDTypography>
                    <FormItem as={RadioGroup} row name={`table[${index}].yesOrNo`}>
                      <Stack direction="row">
                        <FormControlLabel value="Y" control={radioUI} label="Yes" />
                        <FormControlLabel value="N" control={radioUI} label="No" />
                      </Stack>
                    </FormItem>
                  </Grid>
                  <Grid item xs={3}>
                    <FormItem
                      as={MoneyInput}
                      variant="standard"
                      name={`table[${index}].value`}
                      label="Value"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MDTypography variant="button" color="black">
                              $
                            </MDTypography>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      onClick={() => {
                        form.setValues({
                          table: form.values.table.filter((i) => i !== one),
                        });
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
            </MDBox>
          )}
        />
      </MDBox>
      <MDBox>
        <MDButton color="green" variant="outlined" onClick={addMoreGapFunding}>
          <MDBox component="img" src={plusIcon} /> &nbsp;Add more
        </MDButton>
        <MDBox width="590px" py={4}>
          <MDInput
            label="Total Bundle Value"
            sx={{ width: 203 }}
            value={numeral(totalCashImpactPrice).format("0,0.00")}
            variant="standard"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MDTypography variant="button" color="black">
                    $
                  </MDTypography>
                </InputAdornment>
              ),
            }}
          />
        </MDBox>
        <FormItem
          as={MDInput}
          placeholder="enter here"
          label="Notes"
          multiline
          type="text"
          name="notes"
          variant="standard"
          fullWidth
        />
      </MDBox>
      <MDBox py={4}>
        <MDTypography>Post-closing strategy</MDTypography>
      </MDBox>
      <MDBox display="flex">
        <MDBox flex={1}>
          <FormItem
            as={MoneyInput}
            name="goalPurchasePrice"
            variant="standard"
            label="Goal Purchase Price"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MDTypography variant="button" color="black">
                    $
                  </MDTypography>
                </InputAdornment>
              ),
            }}
          />
        </MDBox>
        <MDBox flex={1}>
          <MDInput
            multiline
            type="number"
            name="dealBundleValue"
            variant="standard"
            label="Deal Bundle Value"
            fullWidth
            value={dealBoundleValue}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MDTypography variant="button" color="black">
                    $
                  </MDTypography>
                </InputAdornment>
              ),
            }}
          />
        </MDBox>
        <MDBox flex={1}>
          <MDInput
            multiline
            type="number"
            name="gapLeft"
            variant="standard"
            label="Gap Left"
            fullWidth
            value={form.values.goalPurchasePrice - dealBoundleValue}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MDTypography variant="button" color="black">
                    $
                  </MDTypography>
                </InputAdornment>
              ),
            }}
          />
        </MDBox>
      </MDBox>
    </Form>
  );
});

const PostClosingStrategy = forwardRef((_, ref) => {
  const [{ dealBundleWorkSheet, calculateTotal }] = useFlatInject("dealPlanOperation", {
    state: ["dealBundleWorkSheet.postClosingStrategy"],
  });
  const { goalPurchasePrice, dealBundleValue, gapLeft, table, notes } =
    dealBundleWorkSheet.postClosingStrategy;
  const form = useForm({
    initialValues: {
      goalPurchasePrice,
      dealBundleValue,
      gapLeft,
      table,
      notes,
    },
  });
  useImperativeHandle(ref, () => ({
    getFormData: () => form.values,
    validate: () => form.validateForm(),
  }));
  useSyncGoalPrice(form);

  const addMorePostClosing = () => {
    form.setFieldValue("table", [
      ...form.values.table,
      {
        name: "",
        yesOrNo: "Y",
        value: null,
        notes: "",
      },
    ]);
  };

  const sv = totalCashImpactSubject.value;

  const [, , restPrice] = useObserverState(totalCashImpactSubject, sv);

  const totalCashImpactPrice = calculateTotal(form.values.table, ["Y", "N"]);

  return (
    <Form form={form} fieldStyle={fieldStyle} labelSx={labelSx}>
      <MDBox py={2}>
        <FieldArray
          name="table"
          render={() => (
            <MDBox>
              {form.values.table.map((one, index) => (
                <Grid container key={`${one.name + index}`} alignItems="center" spacing={4}>
                  <Grid item xs={6}>
                    <StrategiesSelect name={`table[${index}].name`} type="postCloseStrategies" />
                  </Grid>
                  <Grid item xs={2}>
                    <MDTypography variant="caption">Used</MDTypography>
                    <FormItem as={RadioGroup} row name={`table[${index}].yesOrNo`}>
                      <Stack direction="row">
                        <FormControlLabel value="Y" control={radioUI} label="Yes" />
                        <FormControlLabel value="N" control={radioUI} label="No" />
                      </Stack>
                    </FormItem>
                  </Grid>
                  <Grid item xs={3}>
                    <FormItem
                      as={MoneyInput}
                      variant="standard"
                      name={`table[${index}].value`}
                      label="Value"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MDTypography variant="button" color="black">
                              $
                            </MDTypography>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      onClick={() => {
                        form.setValues({
                          table: form.values.table.filter((i) => i !== one),
                        });
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
            </MDBox>
          )}
        />
      </MDBox>
      <MDBox>
        <MDButton color="green" variant="outlined" onClick={addMorePostClosing}>
          <MDBox component="img" src={plusIcon} /> &nbsp;Add more
        </MDButton>
        <MDBox width="590px" py={4}>
          <MDInput
            label="Total Cash Impact"
            sx={{ width: 203 }}
            value={numeral(totalCashImpactPrice).format("0,0.00")}
            variant="standard"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MDTypography variant="button" color="black">
                    $
                  </MDTypography>
                </InputAdornment>
              ),
            }}
          />
        </MDBox>
        <FormItem
          as={MDInput}
          placeholder="enter here"
          label="Notes"
          multiline
          type="text"
          name="notes"
          variant="standard"
          fullWidth
        />
      </MDBox>
    </Form>
  );
});

const DealNotesForm = forwardRef((_, ref) => {
  const [{ dealBundleWorkSheet, dealReview }] = useFlatInject("dealPlanOperation");
  const form = useForm({
    initialValues: dealBundleWorkSheet.dealNotes,
    onSubmit: _.submit,
  });
  useSyncGoalPrice(form, "bizImpact");
  useImperativeHandle(ref, () => ({
    getFormData: () => form.values,
    validate: () => form.validateForm(),
  }));
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Form form={form} fieldStyle={fieldStyle} labelSx={labelSx}>
      <MDBox pt={3}>
        <MDTypography variant="h6">DEAL BUNDLE NOTES:</MDTypography>
      </MDBox>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2 }} my={3}>
        <Grid item xs={2}>
          <FormItem
            as={MoneyInput}
            label="Empire Impact"
            name="bizImpact"
            variant="standard"
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <FormItem
            as={MoneyInput}
            label="Net Value Added"
            name="netValueAdded"
            variant="standard"
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <FormItem
            as={MoneyInput}
            label="Annual Positive Cash Added"
            name="annualPositiveCashAdded"
            variant="standard"
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <FormItem
            as={SelectWithLabel}
            type="text"
            name="fullyFundedOrPartiallyFunded"
            variant="standard"
            fullWidth
            placeholder="Select option"
            label="Fully Funded or Partially Funded"
          >
            <MenuItem value="Fully">1 - Fully</MenuItem>
            <MenuItem value="Partially">2 - Partially</MenuItem>
          </FormItem>
        </Grid>
        <Grid item xs={3}>
          <FormItem
            as={MDInput}
            type="number"
            name="numberOfTeam"
            variant="standard"
            fullWidth
            placeholder="0"
            label="Number Of Team	"
          />
        </Grid>
      </Grid>
      <FormItem
        as={MDInput}
        multiline
        type="text"
        name="notes"
        variant="standard"
        fullWidth
        label="Notes"
        placeholder="enter here"
      />
      <Grid mt={4} item xs={12} textAlign="right">
        <MDButton
          color="green"
          variant="outlined"
          onClick={() => navigate("/deal-planner", { replace: true })}
          sx={{ mr: 1 }}
        >
          Cancel
        </MDButton>
        <MDButton
          loading={form.isSubmitting}
          disabled={form.isSubmitting || !dealReview.businessName}
          onClick={form.submitForm}
          color="green"
        >
          Save and next
        </MDButton>
      </Grid>
    </Form>
  );
});

function DealBundleWorkSheet() {
  const [{ saveDealBundleWorkSheet }] = useFlatInject("dealPlanOperation");
  const baseFormRef = useRef();
  const location = useLocation();
  const preClosingFormRef = useRef();
  const gapFundingFormRef = useRef();
  const postClosingFormRef = useRef();
  const dealNotesFormRef = useRef();
  const submit = useCallback(async () => {
    const baseFormData = baseFormRef.current?.getFormData?.();
    const preClosingFormData = preClosingFormRef.current?.getFormData?.();
    const gapFundingFormData = gapFundingFormRef.current?.getFormData?.();
    const postClosingFormData = postClosingFormRef.current?.getFormData?.();
    const dealNotesFormData = dealNotesFormRef.current?.getFormData?.();

    try {
      await saveDealBundleWorkSheet({
        ...baseFormData,
        preClosingStrategy: {
          ...preClosingFormData,
          table: preClosingFormData.table,
        },
        gapFundingStrategy: {
          ...gapFundingFormData,
          table: gapFundingFormData.table,
        },
        postClosingStrategy: {
          ...postClosingFormData,
          table: postClosingFormData.table,
        },
        dealNotes: dealNotesFormData,
      });
      Message.success("save success!");
      window.location.href = `${location.pathname + location.search}#deal-pitch`;
    } catch (error) {
      Message.error(error.message);
    }
  }, [saveDealBundleWorkSheet]);
  return (
    <Card id="deal-bundle-work-sheet" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h4">Deal bundle worksheet</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <BaseForm ref={baseFormRef} />
        <PreClosingStrategy ref={preClosingFormRef} submit={submit} />
        <GapFundingStrategy ref={gapFundingFormRef} />
        <PostClosingStrategy ref={postClosingFormRef} />
        <DealNotesForm submit={submit} ref={dealNotesFormRef} />
      </MDBox>
    </Card>
  );
}

export default DealBundleWorkSheet;

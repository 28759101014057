import { getAuth, signInWithPopup } from "firebase/auth";

export const setToken = (token) => {
  localStorage.token = token;
};

export const setUserId = (userId) => {
  localStorage.userId = userId;
};

export const getToken = () => localStorage.token;

export const getUserId = () => localStorage.userId;

export const signinSocial = async (provider, setEr, user, navigate) => {
  const auth = getAuth();
  provider.addScope("email");
  provider.setCustomParameters({ prompt: "select_account" });

  // The signed-in user info.
  try {
    const result = await signInWithPopup(auth, provider);
    const userData = result?.user;
    const idToken = await userData.getIdToken();
    await user.actions.signInSocial(idToken);
    setEr("");
    navigate("/");
  } catch (er) {
    setEr("Can not sign in as this social account");
  }
};

/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useFlatInject } from "store";

// Otis Admin PRO React context
import { useMaterialUIController } from "utils/hooks";

function Sidenav() {
  const [controller] = useMaterialUIController();
  const [{ step }] = useFlatInject("dealPlanOperation", { maps: ["step"] });
  const { darkMode } = controller;
  const route = useLocation();

  const sidenavItems = useMemo(
    () =>
      [
        { icon: "receipt_long", label: "Deal Overview", href: "deal-review" },
        { icon: "receipt_long", label: "Deal Analysis", href: "deal-analysis" },
        // { icon: "receipt_long", label: "Deal Strategies", href: "deal-strategies" },
        { icon: "receipt_long", label: "Deal Bundle WorkSheet", href: "deal-bundle-work-sheet" },
        { icon: "receipt_long", label: "Deal Summary", href: "deal-pitch" },
        { icon: "receipt_long", label: "Term Sheet", href: "term-sheet" },
        { icon: "receipt_long", label: "Deal Discovery", href: "deal-due" },
        // { icon: "receipt_long", label: "Deal Repitch", href: "second-deal-pitch" },
        { icon: "receipt_long", label: "Deal Repitch Term Sheet", href: "final-term-sheet" },
        { icon: "receipt_long", label: "File Upload", href: "file-upload" },
      ].filter((_, index) => index < step),
    [step]
  );

  const renderSidenavItems = sidenavItems.map(({ icon, label, href, defaultActive }, key) => {
    const itemKey = `item-${key}`;

    return (
      <MDBox key={itemKey} className="no-print" component="li" pt={key === 0 ? 0 : 1}>
        <MDTypography
          component="a"
          href={`#${href}`}
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          sx={({
            borders: { borderRadius },
            functions: { pxToRem },
            palette: { light, yellow },
            transitions,
          }) => ({
            display: "flex",
            alignItems: "center",
            padding: `${pxToRem(18)} ${pxToRem(22)}`,
            transition: transitions.create("background-color", {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.shorter,
            }),
            "&:hover": {
              backgroundColor: light.main,
            },
            ...((route.hash ? route.hash === `#${href}` : key === 0) && {
              backgroundColor: yellow.main,
              fontWeight: 600,
              "&:hover": {
                backgroundColor: "none",
              },
            }),
          })}
        >
          {/* <MDBox mr={1.5} lineHeight={1} color={darkMode ? "white" : "dark"}> */}
          {/*  <Icon fontSize="small">{icon}</Icon> */}
          {/* </MDBox> */}
          {label}
        </MDTypography>
      </MDBox>
    );
  });

  return (
    <Card
      className="no-print"
      sx={{
        borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
        position: "sticky",
        top: "1%",
        overflow: "hidden",
      }}
    >
      <MDBox component="ul" display="flex" flexDirection="column" sx={{ listStyle: "none" }}>
        {renderSidenavItems}
      </MDBox>
    </Card>
  );
}

export default Sidenav;

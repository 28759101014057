/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useRef } from "react";

// react-router components
import { useLocation, Link, NavLink, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDBadge from "components/MDBadge";

// Otis Admin PRO React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Otis Admin PRO React context
// eslint-disable-next-line import/no-duplicates
import { useMaterialUIController, useClickOutside } from "utils/hooks";
import { useFlatInject } from "store";
import { Modal } from "utils/modal";

import MDTypography from "components/MDTypography";
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import LogoutIcon from "assets/images/small-logos/logout.svg";
import UserIcon from "assets/images/small-logos/user.svg";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [modalUser, setModaluser] = useState(false);
  const [controller, { setTransparentNavbar, setMiniSidenav, setOpenConfigurator }] =
    useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const navigate = useNavigate();
  const refSideNav = useRef();
  const [user] = useFlatInject("user");

  const logout = async () => {
    await Modal.confirm({
      title: "Warning",
      content: "Are your sure to logout?",
    });
    await user.logout();
    navigate("/sign-in");
  };
  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar((fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /**
     The event listener that's calling the handleTransparentNavbar function when
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [fixedNavbar]);

  const handleMiniSidenav = () => {
    setTimeout(() => {
      setMiniSidenav(!miniSidenav);
    }, 100);
  };
  const handleConfiguratorOpen = () => setOpenConfigurator(!openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  useClickOutside(refSideNav, () => setModaluser(false));

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      className="no-print"
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" />
      <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });
  const formatRouteName = (s) =>
    s
      .split("-")
      .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
      .join(" ");

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      className="no-print"
      ref={refSideNav}
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar
        className="no-print"
        sx={{
          ...(theme) => navbarContainer(theme),
        }}
      >
        <MDBox
          color="inherit"
          mb={{ xs: 1, md: 0 }}
          sx={{ ...(theme) => navbarRow(theme, { isMini }) }}
        >
          <MDTypography
            fontSize={{ xs: "20px", lg: "32px" }}
            sx={{
              color: "#282828",
              whiteSpace: "nowrap",
              minWidth: { xs: "250px", lg: "400px" },
            }}
          >
            {formatRouteName(route[route.length - 1])}
          </MDTypography>

          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox color={light ? "white" : "inherit"}>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              {renderMenu()}
            </MDBox>
          </MDBox>
        </MDBox>
        <SidenavCollapse
          name={user?.user?.name ?? "User name"}
          active={modalUser}
          open={modalUser}
          sx={{ width: "fit-content", padding: 0, marginTop: "70px" }}
          blackArrow
          userCollapse
          onClick={() => setModaluser((pre) => !pre)}
        >
          <MDBox
            sx={{
              borderRadius: "8px",
              padding: "21px 24px",
              backgroundColor: "#004225",
              position: "absolute",
              right: "0",
              top: { xs: "64px", md: "36px", lg: "48px" },
            }}
          >
            <MDBox
              sx={{
                display: "flex",
                gap: "8px",
                minWidth: "180px",
                padding: "16px 0",
                margin: "auto 0",
                cursor: "pointer",
              }}
            >
              <MDBox component="img" src={UserIcon} />
              <Link to="/my-profile">
                <MDTypography sx={{ color: "#FFFFFF", fontSize: "14px" }}>My profile</MDTypography>
              </Link>
            </MDBox>

            <MDBox
              onClick={logout}
              sx={{
                display: "flex",
                gap: "8px",
                minWidth: "180px",
                padding: "16px 0",
                margin: "auto 0",
                cursor: "pointer",
              }}
            >
              <MDBox component="img" src={LogoutIcon} />
              <MDTypography sx={{ color: "#FFFFFF", fontSize: "14px" }}>Log out</MDTypography>
            </MDBox>
          </MDBox>
        </SidenavCollapse>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            {/* <MDBox pr={1}> */}
            {/*  <MDInput label="Search here" /> */}
            {/* </MDBox> */}
            <MDBox color={light ? "white" : "inherit"}>
              {/* <Link to="/authentication/sign-in/basic"> */}
              {/* <IconButton sx={navbarIconButton} size="small" disableRipple> */}
              {/*   <Icon sx={iconsStyle}>account_circle</Icon> */}
              {/* </IconButton> */}
              {/* </Link> */}
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              {/* <IconButton */}
              {/*  size="small" */}
              {/*  disableRipple */}
              {/*  color="inherit" */}
              {/*  sx={navbarIconButton} */}
              {/*  onClick={handleConfiguratorOpen} */}
              {/* > */}
              {/*  <Icon sx={iconsStyle}>settings</Icon> */}
              {/* </IconButton> */}
              {/* <IconButton */}
              {/*  size="small" */}
              {/*  disableRipple */}
              {/*  color="inherit" */}
              {/*  sx={navbarIconButton} */}
              {/*  aria-controls="notification-menu" */}
              {/*  aria-haspopup="true" */}
              {/*  variant="contained" */}
              {/*  onClick={handleOpenMenu} */}
              {/* > */}
              {/*  <MDBadge badgeContent={9} color="error" size="xs" circular> */}
              {/*    <Icon sx={iconsStyle}>notifications</Icon> */}
              {/*  </MDBadge> */}
              {/* </IconButton> */}
              {renderMenu()}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
      <MDBox
        sx={{
          borderBottom: { md: "1px solid rgba(0, 0, 0, 0.08)" },
          width: "100%",
          height: "1px",
          marginTop: "16px",
        }}
      />
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;

/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Autocomplete from "@mui/material/Autocomplete";
import { Select, MenuItem } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

// Data
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { ErrorMessage } from "formik";
import { useForm, Form, FormItem } from "components/Form";
import {
  preCloseStrategies,
  postCloseStrategies,
  gapFundingStrategies,
} from "constants/strategies";
import StrategiesSelect from "../create-and-edit/components/StrategiesSelect";

function MyAutocomplete({ onChange, ...props }) {
  const localOnChange = (event, newValue, reason) => {
    if (event.type === "keydown" && event.key === "Backspace" && reason === "removeOption") {
      return;
    }
    return onChange(newValue);
  };
  return <Autocomplete {...props} onChange={localOnChange} />;
}

function Shower({ value, description }) {
  return (
    <MDTypography variant="body2" mb={2}>
      <MDBox component="b" display="inline-block" width={100}>
        Value:{" "}
      </MDBox>
      {value} <br />
      <MDBox component="b" display="inline-block" width={100}>
        Description:{" "}
      </MDBox>
      {description} <br />
    </MDTypography>
  );
}

function DealReview() {
  const form = useForm({
    initialValues: {
      preCloseStrategies: null,
      postCloseStrategies: null,
      gapFunding: null,
    },
  });
  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />
      <MDBox mt={10}>
        <Card id="deal-strategies" sx={{ overflow: "visible" }}>
          <MDBox p={3}>
            <MDTypography variant="h4">Deal Strategies</MDTypography>
          </MDBox>
          <MDBox component="form" pb={3} px={3}>
            <Form form={form} fieldStyle={{ flex: 1 }} labelSx={{ display: "none" }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <MDTypography>Pre Close Strategies</MDTypography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <MDBox>
                        {preCloseStrategies.map((i) => (
                          <Shower key={i.value} {...i} />
                        ))}
                      </MDBox>
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <MDTypography>Post Close Strategies</MDTypography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <MDBox>
                        {postCloseStrategies.map((i) => (
                          <Shower key={i.value} {...i} />
                        ))}
                      </MDBox>
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <MDTypography>Gap Funding Strategies</MDTypography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <MDBox>
                        {gapFundingStrategies.map((i) => (
                          <Shower key={i.value} {...i} />
                        ))}
                      </MDBox>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            </Form>
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default DealReview;
